import { Cell, Row, Table } from "@tanstack/react-table";
import { Alignment } from "exceljs";

import variables from "../shared/_exports.module.scss";
import { getExcelTypeHeader } from "../shared/excel/utils";
import { colorHEXToARGB } from "../shared/utils";

import { DIFFERENCE_POSITION_ENUM } from "./Compare.types";
import {
  ICompareRow,
  TCellComponentValue,
} from "./CompareTable/CompareTable.types";
import {
  cellIsLineDifference,
  getCommentFromCellContextOrFalse,
  getFullNomenclature,
  getNumberDifferencePositionFromThreshold,
  getValueNumberDifference,
} from "./CompareTable/CompareTableUtils";
import { TOTAL_ID } from "./CompareTable/useCompareTable";
import {
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "./Table.types";

/** returns the font color for a tender cell */
export function getTenderCellFontColor(
  cell: Cell<ICompareRow, unknown>,
  table: Table<ICompareRow>
) {
  const columnDataType = cell.column.columnDef.meta?.dataType;
  if (Object.values(TABLE_COLUMN_DATA_TYPE_ENUM).includes(columnDataType!)) {
    const numberDifferenceSettings =
      table.options.meta?.compareDifferenceSettings?.number_difference;
    const difference = getValueNumberDifference(
      columnDataType as
        | TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
        | TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE
        | TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
      cell.getContext(),
      numberDifferenceSettings
    );
    if (
      numberDifferenceSettings !== undefined &&
      difference !== undefined &&
      !isNaN(difference)
    ) {
      const differencePosition = getNumberDifferencePositionFromThreshold(
        difference,
        numberDifferenceSettings
      );
      if (differencePosition === DIFFERENCE_POSITION_ENUM.ABOVE) {
        return variables["danger"];
      } else if (differencePosition === DIFFERENCE_POSITION_ENUM.BELOW) {
        return variables["blue400"];
      }
    }
  }
  return undefined;
}

export function getCellColor<T>(cell: Cell<T, any>, row: Row<T>) {
  if (cellIsLineDifference(cell, row)) {
    return colorHEXToARGB(variables["line-difference"]);
  }
}

export function mapCell(
  cell: Cell<ICompareRow, unknown>,
  cellIndex: number,
  /** 1 indented index on Excel sheet */
  rowNumber: number
) {
  const getCellValue = () =>
    cell.getValue<TCellComponentValue>()?.value ?? cell.getValue() ?? "";
  switch (cell.column.columnDef.meta?.dataType) {
    case TABLE_COLUMN_DATA_TYPE_ENUM.NOMENCLATURE:
      return getFullNomenclature(cell.getContext());
    case TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS:
      return (
        getCommentFromCellContextOrFalse(cell.getContext()) || {
          message: "",
        }
      ).message;
    case TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE:
      const baseFormulaExcelCell = {
        result: getCellValue(),
        formula: "",
        // ExcelJS mandatory but could get optional https://github.com/exceljs/exceljs/pull/1796
        date1904: false,
      };

      // different excel formulas to sum up into the final formula
      const formulaParts: string[] = [];

      const isGrandTotalRow = cell.row.original.id === TOTAL_ID;

      const columnIdPrefix = cell.column.id.replace(
        `.${TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE}`,
        ""
      );
      const rowCells = cell.row.getVisibleCells().filter(
        // skip logs as they have a cell but aren't displayed in a column
        ({
          column: {
            columnDef: { meta },
          },
        }) => meta?.dataType !== TABLE_COLUMN_DATA_TYPE_ENUM.LOGS
      );

      const quantityCellIndex = rowCells.findIndex(
        (cell) =>
          cell.column.columnDef.id ===
          `${columnIdPrefix}.${TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY}`
      );
      const quantity = rowCells[quantityCellIndex].getValue();

      const unitPriceCellIndex = rowCells.findIndex(
        (cell) =>
          cell.column.columnDef.id ===
          `${columnIdPrefix}.${TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE}`
      );
      const unitPrice = rowCells[unitPriceCellIndex].getValue();

      if (unitPrice && quantity) {
        // total is from unit price x quantity
        const quantityColumnCharIndex = getExcelTypeHeader(quantityCellIndex);

        const unitPriceColumnCharIndex = getExcelTypeHeader(unitPriceCellIndex);

        formulaParts.push(
          `${quantityColumnCharIndex}${rowNumber}*${unitPriceColumnCharIndex}${rowNumber}`
        );
      }

      if (
        (cell.row.subRows.length > 0 || isGrandTotalRow) &&
        typeof cell.getValue() === "number"
      ) {
        const columnId = cell.column.id;
        const cellCharIndex = getExcelTypeHeader(cellIndex);

        // cell can be from the grand total row or just a parent (middle or top) row total
        const subRowsToConsiderForTotalFormula = isGrandTotalRow
          ? cell
              .getContext()
              .table.getExpandedRowModel()
              .rows.filter(
                ({ original: { id }, depth }) => id !== TOTAL_ID && depth === 0
              )
          : // we can't have a total formula if its sub rows aren't displayed
          cell.row.getIsExpanded()
          ? cell.row.subRows
          : [];
        // this calculation only works because all sub rows to consider are under the one being processed
        // a change of row order will break this
        const rowNumbersForTotalFormula = getRowNumberListForTotalFormula(
          subRowsToConsiderForTotalFormula,
          // we use the row just after the current one to start calculation
          rowNumber + 1,
          columnId
        );

        if (rowNumbersForTotalFormula.length > 0) {
          formulaParts.push(
            convertToExcelSumFormula(rowNumbersForTotalFormula, cellCharIndex)
          );
        }
      }

      if (formulaParts.length) {
        return {
          ...baseFormulaExcelCell,
          formula: formulaParts.filter(Boolean).join("+"),
        };
      }

      return getCellValue();

    default:
      return getCellValue();
  }
}

function getRowNumberListForTotalFormula(
  rows: Row<ICompareRow>[],
  currentRowNumber: number,
  columnId: string
) {
  return rows.reduce(
    ({ currentRowNumber, rowNumbersForTotalFormula }, row) => {
      const leafRowLength = getAllExpandedSubRowsLength(row);
      return {
        currentRowNumber: currentRowNumber + leafRowLength + 1,
        rowNumbersForTotalFormula: [
          ...rowNumbersForTotalFormula,
          currentRowNumber,
        ],
      };
    },
    {
      currentRowNumber,
      rowNumbersForTotalFormula: [] as number[],
    }
  ).rowNumbersForTotalFormula;
}

function getAllExpandedSubRowsLength(row: Row<ICompareRow>): number {
  return row.getIsExpanded()
    ? row.subRows.reduce(
        (total, row) => total + 1 + getAllExpandedSubRowsLength(row),
        0
      )
    : 0;
}

export function getCellAlignment<T>(
  cell: Cell<T, unknown>,
  level?: number,
  maxLevel: number = MAX_SUPPORTED_DEPTH
): Partial<Alignment> | undefined {
  if (
    level !== undefined &&
    cell.column.columnDef.meta?.dataType ===
      TABLE_COLUMN_DATA_TYPE_ENUM.DESIGNATION
  ) {
    return {
      // indent is 0 based, level is 1
      indent: maxLevel - level - 1,
      horizontal: "left",
      readingOrder: "ltr",
    };
  }
}

/** SUM all the rows, converts to ranges when appropriate */
function convertToExcelSumFormula(
  rowNumbersForTotalFormula: number[],
  cellCharIndex: string
) {
  const ranges = consolidateIntoRanges(rowNumbersForTotalFormula);

  return `SUM(${ranges
    .map(
      (range) =>
        `${cellCharIndex}${range.start}${
          range.end ? `:${cellCharIndex}${range.end}` : ""
        }`
    )
    .join(",")})`;
}

/** create ranges from rows when they are continuous */
function consolidateIntoRanges(rows: number[]) {
  const ranges: { start: number; end?: number }[] = [];
  let previousRow: number;
  rows.forEach((row) => {
    if (row === previousRow + 1) {
      ranges[ranges.length - 1].end = row;
    } else {
      ranges.push({ start: row });
    }
    previousRow = row;
  });
  return ranges;
}
