import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { MinMaxField } from "../operations/filters/MinMaxField";
import { DefaultModal } from "../shared/DefaultModal";
import colors from "../shared/_exports.module.scss";
import { useTranslation, yupLocale } from "../shared/i18n";
import { FrontArrowIcon, TrashIcon } from "../shared/icons";
import { WorkTypeSelect } from "../shared/inputs";
import {
  AllotmentSelect,
  CONVENTIONAL_ALLOTMENTS,
} from "../shared/inputs/AllotmentSelect";
import {
  ControlledInput,
  INPUT_TYPES_ENUM,
  getUndefinedSafeSelectOnChange,
  yupNumberOptionalNotNegative,
} from "../shared/inputs/input";
import { IPriceAveragePanel } from "../shared/types/averagePrice.types";
import { STATUS_ENUM, WORK_TYPE_ENUM } from "../shared/types/operation.types";
import { useFilteredLotsWithMappedTendersQuery } from "../shared/useSharedQueries";
import { ifTest, testLessThanOrOptional } from "../shared/utils";

yup.setLocale(yupLocale);
const schema = yup.object({
  filters: yup
    .object({
      work_type: yup.mixed<WORK_TYPE_ENUM>(),
      conventional_allotments: yup
        .string()
        .required()
        .oneOf(CONVENTIONAL_ALLOTMENTS.flatMap(({ options }) => options)),
      work_floor_area_gte: yupNumberOptionalNotNegative().test(
        (value, context) =>
          testLessThanOrOptional(value, context.parent.work_floor_area_lte)
      ),
      work_floor_area_lte: yupNumberOptionalNotNegative().test(
        (value, context) =>
          testLessThanOrOptional(context.parent.work_floor_area_gte, value)
      ),
      permit_date_gte: yup
        .date()
        .nullable()
        .transform((v) => (!isNaN(v) ? v : null))
        .test(
          // ensure value >= permit_date_lte if both are present
          (value, context) =>
            testLessThanOrOptional(value, context.parent.permit_date_lte)
        ),
      permit_date_lte: yup
        .date()
        .nullable()
        .transform((v) => (!isNaN(v) ? v : null))
        .test(
          // ensure value <= permit_date_gte if both are present
          (value, context) =>
            testLessThanOrOptional(context.parent.permit_date_gte, value)
        ),
      tender_date_gte: yup
        .date()
        .nullable()
        .transform((v) => (!isNaN(v) ? v : null))
        .test((value, context) =>
          testLessThanOrOptional(value, context.parent.tender_date_lte)
        ),
      tender_date_lte: yup
        .date()
        .nullable()
        .transform((v) => (!isNaN(v) ? v : null))
        .test((value, context) =>
          testLessThanOrOptional(context.parent.tender_date_gte, value)
        ),
      estimation_amount_gte: yupNumberOptionalNotNegative().test(
        (value, context) =>
          testLessThanOrOptional(value, context.parent.estimation_amount_lte)
      ),
      estimation_amount_lte: yupNumberOptionalNotNegative().test(
        (value, context) =>
          testLessThanOrOptional(context.parent.estimation_amount_gte, value)
      ),
      project_status: yup
        .mixed<STATUS_ENUM>()
        .oneOf(Object.values(STATUS_ENUM)),
    })
    .optional(),
});

export type TPriceAverageFiltersFormInputs = yup.InferType<typeof schema>;
export type TPriceAverageFilters = Exclude<
  TPriceAverageFiltersFormInputs["filters"],
  undefined
>;

enum STEP {
  FILTERS,
  SELECTION,
}

export function PriceAverageFiltersModal({
  show,
  priceAveragePanel,
  handleClose,
  onSubmit,
}: {
  show: boolean;
  priceAveragePanel: IPriceAveragePanel;
  handleClose: () => void;
  onSubmit(panel: Partial<IPriceAveragePanel>): void;
}) {
  const { t } = useTranslation("PriceAverageFiltersModal");

  //TODO: second step with selection table
  const [currentStep] = useState<STEP>(STEP.FILTERS);
  const [forceRefreshKey, setForceRefreshKey] = useState(String(Math.random()));

  return (
    <DefaultModal
      show={show}
      title={
        <Row>
          <Col className="text-start">{t("title")}</Col>
          {currentStep === STEP.FILTERS && (
            <Col xs="auto">
              <Button
                variant="outline-input me-4"
                onClick={() => {
                  priceAveragePanel.settings.filters = {};
                  setForceRefreshKey(String(Math.random()));
                }}
              >
                <TrashIcon color={colors.black} className="me-2" />
                {t("reset all filters")}
              </Button>
            </Col>
          )}
          <Col xs="auto">
            <Button
              variant="text"
              className="text-decoration-underline fs-6"
              onClick={() => handleClose()}
            >
              {t("close")}
            </Button>
          </Col>
        </Row>
      }
      handleClose={handleClose}
      staticBackdrop
      size="lg"
      unCancelable
    >
      {currentStep === STEP.FILTERS && (
        <PriceAverageFilters
          key={forceRefreshKey}
          priceAveragePanel={priceAveragePanel}
          //TODO: second step with selection table
          // onSubmit={() => setCurrentStep(STEP.SELECTION)}
          onSubmit={(filters, price_breakdowns) => {
            const panel: Partial<IPriceAveragePanel> = priceAveragePanel ?? {};
            panel.settings = panel.settings ?? {};
            panel.settings.filters = filters;
            panel.price_breakdowns = price_breakdowns;
            onSubmit(panel);
          }}
        />
      )}
      {/* TODO: second step with selection table */}
      {currentStep === STEP.SELECTION && <div>selection</div>}
    </DefaultModal>
  );
}

interface IFilters {
  priceAveragePanel: IPriceAveragePanel;
  onSubmit: (
    filters: Partial<TPriceAverageFilters>,
    price_breakdowns: string[]
  ) => void;
}

function PriceAverageFilters({ priceAveragePanel, onSubmit }: IFilters) {
  const { t } = useTranslation("PriceAverageFiltersModal");
  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, control, setValue, watch } =
    useForm<TPriceAverageFiltersFormInputs>({
      resolver: yupResolver(schema),
      values: priceAveragePanel?.settings as TPriceAverageFiltersFormInputs,
      mode: "onSubmit",
    });

  const [filters, setFilters] = useState<Partial<TPriceAverageFilters>>({});

  // get a filtered list of allotments with their respectively filtered mapped tenders
  const lots = useFilteredLotsWithMappedTendersQuery(filters, {
    enabled: Boolean(filters.conventional_allotments),
  });
  const tenderIds = lots.data
    ?.flatMap((lot) => lot.pricebreakdowns)
    .map((pricebreakdown) => pricebreakdown.id);

  /** update filters with form values */
  const updateFilters = useCallback(() => {
    const newFilters = Object.entries(watch("filters") ?? {}).reduce(
      (previous, [key, value]) => {
        if (value !== undefined && value !== null && (value as string) !== "") {
          previous[key as keyof TPriceAverageFilters] = value as any;
        }
        return previous;
      },
      {} as Partial<TPriceAverageFilters>
    );
    setFilters(newFilters);
  }, [watch]);

  /** update the filtered lots and mapped tender query on form filter changes */
  const updateOnFormChanges = useCallback(() => {
    updateFilters();
    setDisabled(false);
  }, [updateFilters]);

  return (
    <Form
      className="mt-3"
      onBlur={updateOnFormChanges}
      onSubmit={handleSubmit(() => onSubmit(filters, tenderIds!))}
      data-test={ifTest("price-average-filters-modal-form")}
    >
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          label={t("conventional allotments")}
          name="filters.conventional_allotments"
          schema={schema}
          control={control}
          render={({ field }) => (
            <AllotmentSelect
              {...field}
              onChange={field.onChange}
              multi={false}
            />
          )}
          onChange={updateFilters}
        />
        <ControlledInput
          as={Col}
          name="filters.work_type"
          label={t("workTypeLabel")}
          schema={schema}
          control={control}
          render={({ field }) => (
            <WorkTypeSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
          onChange={updateFilters}
        />
      </Row>
      <Row>
        <Col onChange={() => setDisabled(true)}>
          <MinMaxField
            label={t("work_floor")}
            register={register}
            unit="m²"
            type={INPUT_TYPES_ENUM.NUMBER}
            watch={watch}
            filterKey="filters.work_floor_area"
            handleClear={() => {
              setValue("filters.work_floor_area_lte", undefined);
              setValue("filters.work_floor_area_gte", undefined);
              updateOnFormChanges();
            }}
          />
        </Col>
        <Col onChange={() => setDisabled(true)}>
          <MinMaxField
            label={t("permit_date")}
            register={register}
            type={INPUT_TYPES_ENUM.DATE}
            watch={watch}
            filterKey="filters.permit_date"
            handleClear={() => {
              setValue("filters.permit_date_gte", undefined);
              setValue("filters.permit_date_lte", undefined);
              updateOnFormChanges();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col onChange={() => setDisabled(true)}>
          <MinMaxField
            label={t("estimation_amount")}
            register={register}
            unit="€"
            type={INPUT_TYPES_ENUM.NUMBER}
            watch={watch}
            filterKey="filters.estimation_amount"
            handleClear={() => {
              setValue("filters.estimation_amount_gte", undefined);
              setValue("filters.estimation_amount_lte", undefined);
              updateOnFormChanges();
            }}
          />
        </Col>
        <Col onChange={() => setDisabled(true)}>
          <MinMaxField
            label={t("tender_date")}
            register={register}
            type={INPUT_TYPES_ENUM.DATE}
            watch={watch}
            filterKey="filters.tender_date"
            handleClear={() => {
              setValue("filters.tender_date_gte", undefined);
              setValue("filters.tender_date_lte", undefined);
              updateOnFormChanges();
            }}
          />
        </Col>
      </Row>
      <Row>
        <hr className="my-2" />
      </Row>
      {/* TODO: additional fields based on conventional_allotment */}
      <Row className="my-4 justify-content-end">
        <Col xs="auto">
          <Button size="lg" type="submit" disabled={disabled || lots.isLoading}>
            {lots.isLoading ? (
              <Spinner size="sm" />
            ) : (
              t("display results", { count: tenderIds?.length })
            )}
            <FrontArrowIcon fill={colors.white} className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
