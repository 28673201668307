import { parseISO } from "date-fns";

import { TPriceAverageFilters } from "../../PriceAverage/PriceAverageFiltersModal";
import { IPriceAverageSettings } from "../../PriceAverage/PriceAverageTable.types";
import { toISODate } from "../../api/APIUtils";
import { ITableRow, ITableRowMeta } from "../../compare/Table.types";

import { TCONSTRUCTION_INDICATOR_OPTIONS } from "./rowType.types";

export interface IFilters {
  filters: {
    conventional_allotments?: string;
    [key: string]: any;
  };
}

export interface IPriceAveragePanelDTO {
  id: string;
  name: string;
  description: string;
  creation_date?: string;
  price_breakdowns: string[];
  settings: Partial<IFilters> &
    Partial<IPriceAverageSettings> &
    Partial<TPriceAverageFilters>;
  ignored_classifications: { [key: string]: string[] };
}

export interface IPriceAveragePanel {
  id: string;
  name: string;
  description: string;
  creation_date?: Date;
  price_breakdowns: string[];
  settings: Partial<IFilters> &
    Partial<IPriceAverageSettings> &
    Partial<TPriceAverageFilters>;
  ignored_classifications: { [key: string]: string[] };
}

export function priceAveragePanelDTOToPriceAveragePanelMapper(
  price_average_panel: IPriceAveragePanelDTO
): IPriceAveragePanel {
  return {
    ...price_average_panel,
    creation_date: price_average_panel.creation_date
      ? parseISO(price_average_panel.creation_date)
      : undefined,
  };
}

export function priceAveragePanelToPriceAveragePanelDTOMapper(
  price_average_panel: Partial<IPriceAveragePanel>
): Partial<IPriceAveragePanelDTO> {
  return {
    ...price_average_panel,
    creation_date: toISODate(price_average_panel.creation_date),
  };
}

export interface IPriceAverage {
  ref: string;
  tenders: { [key: string]: number };
}

export interface IPriceAverageRowMeta extends ITableRowMeta {
  tenderId: string;
}

export interface IPriceAverageRow
  extends IPriceAverage,
    ITableRow<IPriceAverageRow> {
  subRows?: IPriceAverageRow[];
  meta: IPriceAverageRowMeta;
}

export type TConstructionIndicatorMultipliersDTO = (
  | string
  | number
  | undefined
)[][];

export type TConstructionIndicatorMultipliers = {
  [index in TCONSTRUCTION_INDICATOR_OPTIONS | "dates"]?: (
    | string
    | number
    | undefined
  )[];
};

export function constructionIndicatorDTOtoConstructionIndicatorMapper(
  constructionIndicator: TConstructionIndicatorMultipliersDTO
) {
  const result: TConstructionIndicatorMultipliers = {
    dates: constructionIndicator[0].slice(1),
  };
  constructionIndicator
    .slice(1)
    .forEach(
      (row) =>
        (result[row[0] as TCONSTRUCTION_INDICATOR_OPTIONS] = row.slice(1))
    );
  return result;
}
