import {
  Cell,
  CellContext,
  Table as ReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useMemo } from "react";
import { UseTranslationOptions } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ITableRow } from "../compare/Table.types";
import {
  LotWithConventionalAllotments,
  lotWithConventionalAllotmentsToString,
} from "../shared/ConventionalAllotments";
import colors from "../shared/_exports.module.scss";
import { ExportTableToExcel } from "../shared/excel/ExportTableToExcel";
import {
  formatCurrency,
  useEnumTranslation,
  useTranslation,
} from "../shared/i18n";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { ILot, ILotPrice } from "../shared/types/lot.types";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  isDevelopmentEnv,
} from "../shared/utils";

import styles from "./LotsPage.module.scss";

const emptyData: any[] = [];
const LOTS_PAGE_SIZE = 20;

function getColumnTotal(
  lots: ILot[] | undefined,
  key:
    | "min_price_breakdowns"
    | "max_price_breakdowns"
    | "avg_estimation_amount"
    | "estimation_amount"
    | "best_estimation_amount"
) {
  return (
    lots?.reduce((total: number, lot: ILot) => {
      switch (key) {
        case "min_price_breakdowns":
        case "max_price_breakdowns":
          return (
            total + Number(lot[key]?.find(Boolean)?.estimation_amount ?? 0)
          );
        case "avg_estimation_amount":
        case "estimation_amount":
        default:
          return total + Number(lot[key] ?? 0);
        case "best_estimation_amount":
          return (
            total + Number(lot.best_price_breakdown?.estimation_amount ?? 0)
          );
      }
    }, 0) ?? 0
  );
}

function CompanyOfferCell(props: CellContext<ILot, ILotPrice[]>) {
  const tenders = props.getValue();
  const hasMultipleTenders = (tenders?.length ?? 0) > 1;
  const firstTender = tenders?.find(Boolean);
  return (
    <CompanyOffer
      tender={firstTender}
      hasMultipleTenders={hasMultipleTenders}
    />
  );
}

function BestCompanyOfferCell(props: CellContext<ILot, ILotPrice>) {
  return <CompanyOffer tender={props.getValue()} />;
}

function CompanyOffer({
  tender,
  hasMultipleTenders,
}: {
  tender?: ILotPrice;
  hasMultipleTenders?: Boolean;
}) {
  return tender ? (
    <>
      <b>
        {tender.company_name}
        {hasMultipleTenders && "..."}
      </b>
      <br />
      {formatCurrency(tender.estimation_amount)}
    </>
  ) : (
    <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>
  );
}

const LARGE_COL_WIDTH = 30;

enum TABLE_COLUMN_DATA_TYPE_ENUM {
  NAME = "NAME",
  OFFER = "OFFER",
  NUMBER = "AVERAGE",
}

function mapCell(
  cell: Cell<ITableRow<unknown>, unknown>,
  tEnum: (
    key: string,
    options?: UseTranslationOptions<undefined> | undefined
  ) => string
) {
  switch (cell.column.columnDef.meta?.dataType) {
    case TABLE_COLUMN_DATA_TYPE_ENUM.NAME:
      return lotWithConventionalAllotmentsToString(
        cell.row.original as ILot,
        tEnum
      );
    case TABLE_COLUMN_DATA_TYPE_ENUM.OFFER:
      const tenders = cell.getValue<ILotPrice[]>();
      const firstTender = tenders?.find(Boolean);
      if (!firstTender) {
        return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
      }
      const hasMultipleTenders = (tenders?.length ?? 0) > 1;
      return `${firstTender.company_name}${
        hasMultipleTenders ? "..." : ""
      }: ${formatCurrency(firstTender.estimation_amount)}`;
    case TABLE_COLUMN_DATA_TYPE_ENUM.NUMBER:
      return formatCurrency(cell.getValue<number>());
    default:
      return cell.getValue<any>()?.value ?? cell.getValue() ?? "";
  }
}

const columnHelper = createColumnHelper<ILot>();

export function OngoingLotsTable({
  lots,
  isLoading,
  operationId,
}: {
  lots?: ILot[];
  isLoading?: boolean;
  operationId: string;
}) {
  const { tEnum } = useEnumTranslation();

  const { t } = useTranslation("OngoingLotsTable");

  const shouldDisplayBestColumn = lots?.some((lot) =>
    Boolean(lot.best_price_breakdown)
  );

  let table: ReactTable<ILot> | undefined = undefined;

  const ongoingLotsColumns = useMemo(
    () => [
      columnHelper.accessor("reference_number", {
        header: () => (
          <>
            <span className="me-5">{t("reference_number")}</span>
            <ExportTableToExcel
              table={table as any}
              isLoading={Boolean(isLoading)}
              fileName={`${t("title")}_${operationId}`}
              sheetName={t("title")}
              mapCell={(cell) => mapCell(cell, tEnum)}
              getCellColor={() => colors.white}
              fontSize={10}
              headerFontSize={10}
              rowHeight={18}
            />
          </>
        ),
        meta: {
          excel: {
            header: t("reference_number"),
          },
        },
      }),
      columnHelper.accessor("name", {
        header: t("name"),
        cell: (props) => (
          <LotWithConventionalAllotments lot={props.row.original} />
        ),
        footer: t("total"),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.NAME,
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      columnHelper.accessor("estimation_amount", {
        header: t("estimation"),
        cell: (props) => formatCurrency(props.getValue()),
        footer: formatCurrency(getColumnTotal(lots, "estimation_amount")),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.NUMBER,
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      columnHelper.accessor("min_price_breakdowns", {
        header: t("min_offer"),
        cell: CompanyOfferCell,
        footer: formatCurrency(getColumnTotal(lots, "min_price_breakdowns")),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.OFFER,
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      columnHelper.accessor("max_price_breakdowns", {
        header: t("max_offer"),
        cell: CompanyOfferCell,
        footer: formatCurrency(getColumnTotal(lots, "max_price_breakdowns")),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.OFFER,
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      columnHelper.accessor("avg_estimation_amount", {
        header: t("average"),
        cell: (props) => formatCurrency(props.getValue()),
        footer: formatCurrency(getColumnTotal(lots, "avg_estimation_amount")),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.NUMBER,
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      ...(shouldDisplayBestColumn
        ? [
            columnHelper.accessor("best_price_breakdown", {
              header: t("best_estimation_amount"),
              cell: BestCompanyOfferCell,
              footer: formatCurrency(
                getColumnTotal(lots, "best_estimation_amount")
              ),
              meta: {
                dataType: TABLE_COLUMN_DATA_TYPE_ENUM.NUMBER,
              },
            }),
          ]
        : []),
    ],
    [t, lots, shouldDisplayBestColumn, table, isLoading, operationId, tEnum]
  );

  table = useReactTable<ILot>({
    enableSorting: false,
    columns: ongoingLotsColumns,
    data: lots ?? emptyData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: isDevelopmentEnv,
    initialState: { pagination: { pageSize: LOTS_PAGE_SIZE } },
  });
  const navigate = useNavigate();

  return (
    <Table
      table={table}
      className={styles["lots-table"]}
      getRowClassName={(row) =>
        classNames(Boolean(row.original.selected) && styles["closed-lot"])
      }
      onRowClick={(row, _, event) =>
        event.ctrlKey
          ? window.open(`./${PATH_NAMES_ENUM.LOTS}/${row.original.id}`)
          : navigate(`${row.original.id}`)
      }
      isLoading={isLoading}
      defaultTheme
      hover
      responsive
    />
  );
}
