import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ColumnDef,
  ExpandedState,
  Row as RowDef,
  RowSelectionState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { GrSort } from "react-icons/gr";
import { NavLink, useParams } from "react-router-dom";

import { GuardedButton } from "../../account/GuardedButton";
import { useOrganizationNameRights } from "../../account/UserOrganizationGuard";
import { updateAllotment } from "../../api/fetchLots";
import colors from "../_exports.module.scss";
import { formatCurrency, useTranslation } from "../i18n";
import { formatDateDDMMYYYY } from "../i18n/dateFormatter";
import { PenIcon, StarIcon, TrashIcon } from "../icons";
import { CheckBox, DropDownCheckBoxButton } from "../inputs";
import { PATH_NAMES_ENUM } from "../pathNames";
import { ICompany } from "../types/company.types";
import { getIsBestPriceBreakdown } from "../types/lot.types";
import { ITender } from "../types/tender.types";
import { useHasChanged } from "../useHasChanged";
import { QUERY_KEYS_ENUM, useCurrentLot } from "../useSharedQueries";
import {
  cancelEvent,
  doArraysHaveDifferentValues,
  ifTest,
  isDevelopmentEnv,
} from "../utils";
import { DIFFERENCE_MODE_ENUM, Difference } from "../visualization/Difference";

import styles from "./useTendersTable.module.scss";

function isCompany(company: ITender | ICompany): company is ICompany {
  return (company as ICompany).tenders !== undefined;
}

function getRowSelectionFromSelectedTenderIds(
  rows: RowDef<ICompany | ITender>[],
  selectedTenderIds?: string[]
) {
  return (
    selectedTenderIds
      ?.map((tenderId) => rows.find((row) => row.original.id === tenderId)?.id)
      .reduce(
        (state, id) =>
          id !== undefined ? Object.assign(state, { [id]: true }) : state,
        {}
      ) ?? {}
  );
}

function onRowClick(row: RowDef<any>) {
  if (row.getCanExpand()) {
    row.toggleExpanded();
  } else {
    row.toggleSelected();
  }
}

const emptyData: ICompany[] = [];
export function useTendersTable({
  companies,
  estimation,
  lowestEstimation,
  canEdit,
  onEditTenderVersion,
  onEditTender,
  onDeleteTender,
  onAddNewTender,
  controlledSelectedTenderIds,
  defaultSelectedTenderIds,
}: {
  companies: ICompany[];
  estimation?: number;
  lowestEstimation?: number;
  canEdit?: boolean;
  onEditTenderVersion?: (tender: ITender) => void;
  onEditTender?: (company: ICompany) => void;
  onDeleteTender?: (tender: ITender) => void;
  onAddNewTender?: (company: ICompany) => void;
  controlledSelectedTenderIds?: string[];
  defaultSelectedTenderIds?: string[];
}) {
  const { t } = useTranslation("useTendersTable");
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { operationId, lotId } = useParams();

  const queryClient = useQueryClient();

  const bestTenderMutation = useMutation({
    mutationFn: updateAllotment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOT, lotId],
      });
    },
  });
  const lot = useCurrentLot();
  const bestLotPrice = lot?.best_price_breakdown;

  const { organization } = useOrganizationNameRights(
    window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION
  );

  const tendersColumns = useMemo(() => {
    const columns: ColumnDef<ICompany | ITender>[] = [
      {
        header: t("company name"),
        accessorKey: "name",
        size: 240,
        cell: (props) => {
          const original = props.row.original;
          if (isCompany(original)) {
            const isBest = getIsBestPriceBreakdown(bestLotPrice, original);
            return (
              <div className="d-flex flex-row flex-nowrap">
                {canEdit && (
                  <GuardedButton
                    variant="text"
                    className="me-3 ms-n2 align-self-start p-3"
                    onClick={(e) => {
                      cancelEvent(e);
                      bestTenderMutation.mutate({
                        id: lotId!,
                        best: isBest
                          ? null
                          : original.tenders.find((tender) => tender)?.id,
                      });
                    }}
                  >
                    <StarIcon
                      fill={isBest ? colors.blue300 : ""}
                      stroke={colors.blue300}
                      className="align-top d-inline-block"
                    />
                  </GuardedButton>
                )}
                <div>
                  <b>{props.getValue<string>()}</b>
                  {canEdit && (
                    <>
                      <GuardedButton
                        variant="text"
                        className={classNames("px-0 pb-0", styles.button)}
                        onClick={(e) => {
                          cancelEvent(e);
                          onEditTender?.(original);
                        }}
                      >
                        <PenIcon className="ms-2" />
                      </GuardedButton>
                      <br />
                      <GuardedButton
                        variant="text"
                        className={classNames("px-0 pb-0 mt-2", styles.button)}
                        onClick={(e) => {
                          cancelEvent(e);
                          onAddNewTender?.(original);
                        }}
                      >
                        <u>{t("add new version")}</u>
                      </GuardedButton>
                    </>
                  )}
                </div>
              </div>
            );
          }
          return (
            <Row>
              <Col xs={4} className="text-end">
                <CheckBox
                  id={`${original.id}-checkbox`}
                  onChange={props.row.getToggleSelectedHandler()}
                  onClick={props.row.getToggleSelectedHandler()}
                  checked={props.row.getIsSelected()}
                  className={styles.checkbox}
                />
              </Col>
              <Col>
                <div className="mb-2">
                  <label htmlFor={`${original.id}-checkbox`} role="button">
                    <b>{t("version", { version: original.version })}</b>
                  </label>
                  {canEdit && (
                    <>
                      <GuardedButton
                        variant="text"
                        className="mx-3"
                        onClick={(e) => {
                          onEditTenderVersion?.(original);
                          cancelEvent(e);
                        }}
                      >
                        <PenIcon />
                      </GuardedButton>
                      <GuardedButton
                        variant="text"
                        onClick={(e) => {
                          onDeleteTender?.(original);
                          cancelEvent(e);
                        }}
                      >
                        <TrashIcon />
                      </GuardedButton>
                      {organization && (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{t("mapping")}</Tooltip>}
                        >
                          <Nav className="d-inline-block">
                            <Nav.Link
                              as={NavLink}
                              to={{
                                pathname: `/${PATH_NAMES_ENUM.MAPPING}/${PATH_NAMES_ENUM.OPERATIONS}/${operationId}/${PATH_NAMES_ENUM.LOTS}/${lotId}`,
                                search: `?tenders=${original.id}`,
                              }}
                              data-test={ifTest("mapping-link")}
                            >
                              <GrSort />
                            </Nav.Link>
                          </Nav>
                        </OverlayTrigger>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {t("creation date")}
                  {formatDateDDMMYYYY(original.upload_date)}
                </div>
              </Col>
            </Row>
          );
        },
      },
      {
        header: t("total amount"),
        id: "total amount",
        accessorKey: "estimation_amount",
        cell: (props) => (
          <span className="ms-3">
            {formatCurrency(props.getValue<number>())}
          </span>
        ),
      },
      {
        header: "",
        id: "arrow",
        size: 20,
        meta: { className: styles["dropdown-col"] },
        cell: (props) =>
          isCompany(props.row.original) && (
            <div className="text-center">
              <DropDownCheckBoxButton
                checked={props.row.getIsExpanded()}
                onChange={(e) => {
                  props.row.toggleExpanded();
                  cancelEvent(e);
                }}
              />
            </div>
          ),
      },
    ];
    if (estimation !== undefined) {
      columns.push({
        header: t("MOE difference"),
        id: "MOE difference",
        accessorKey: "estimation_amount",
        cell: (props) =>
          isCompany(props.row.original) && (
            <Difference
              comparee={props.getValue<number>()}
              comparator={estimation}
              mode={DIFFERENCE_MODE_ENUM.PERCENT}
              className="ps-5"
              showTrend
            />
          ),
      });
    }
    if (lowestEstimation !== undefined) {
      columns.push({
        header: t("difference lowest"),
        accessorKey: "estimation_amount",
        id: "difference lowest",
        cell: (props) =>
          isCompany(props.row.original) && (
            <Difference
              comparee={Number(props.getValue<string>())}
              comparator={lowestEstimation}
              mode={DIFFERENCE_MODE_ENUM.EURO}
              className="ps-5"
              showTrend
            />
          ),
      });
    }
    return columns;
  }, [
    t,
    estimation,
    lowestEstimation,
    canEdit,
    organization,
    operationId,
    lotId,
    bestLotPrice,
    bestTenderMutation,
    onEditTender,
    onAddNewTender,
    onEditTenderVersion,
    onDeleteTender,
  ]);

  const table = useReactTable<ICompany | ITender>({
    enableSorting: false,
    initialState: { pagination: { pageSize: 100 } },
    state: {
      expanded,
      rowSelection,
    },
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    columns: tendersColumns,
    data: (companies ?? emptyData) as (ICompany | ITender)[],
    debugTable: isDevelopmentEnv,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row: any) => row.tenders,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection: true,
  });

  const tableCoreRowModel = table.getCoreRowModel();
  const rowsById = tableCoreRowModel.rowsById;
  const selectedTenderIds = Object.keys(rowSelection).map(
    (rowId) => rowsById[rowId]?.original.id
  );

  const [hasSetDefaults, setHasSetDefaults] = useState(false);
  if (!hasSetDefaults && defaultSelectedTenderIds) {
    // select defaults
    setHasSetDefaults(true);
    const rowSelection = getRowSelectionFromSelectedTenderIds(
      tableCoreRowModel.flatRows,
      defaultSelectedTenderIds
    );
    setRowSelection(rowSelection);
  }

  const controlledSelectedTenderIdsHasChanged = useHasChanged(
    ...(controlledSelectedTenderIds ?? [])
  );
  const companiesHasChanged = useHasChanged(companies);
  if (
    (controlledSelectedTenderIdsHasChanged || companiesHasChanged) &&
    doArraysHaveDifferentValues(
      selectedTenderIds,
      controlledSelectedTenderIds ?? []
    )
  ) {
    const rowSelection = getRowSelectionFromSelectedTenderIds(
      tableCoreRowModel.flatRows,
      controlledSelectedTenderIds
    );
    setRowSelection(rowSelection);
    setExpanded((expanded) => {
      const parentRowIdsOfSelectedRows = Object.keys(rowSelection).map(
        (rowId) => rowsById[rowId].getParentRow()?.id
      );
      const expandedStateFromParentRowIdsOfSelectedRows =
        parentRowIdsOfSelectedRows.reduce(
          (state, parentRowId) =>
            parentRowId !== undefined
              ? Object.assign(state, { [parentRowId]: true })
              : state,
          {}
        );
      return typeof expanded === "boolean"
        ? true
        : { ...expanded, ...expandedStateFromParentRowIdsOfSelectedRows };
    });
  }

  return { table, onRowClick, selectedTenderIds };
}
