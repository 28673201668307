import { CellContext, ColumnDef, HeaderContext } from "@tanstack/react-table";
import classNames from "classnames";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CgArrowsMergeAltH, CgArrowsShrinkH } from "react-icons/cg";
import { FaCircleInfo, FaTriangleExclamation, FaXmark } from "react-icons/fa6";

import colors from "../../shared/_exports.module.scss";
import { ContactSupportOnError } from "../../shared/errors/ContactSupport";
import { DIFFERENCE_COL_WIDTH } from "../../shared/excel/ExportTableToExcel";
import { getExcelTypeHeader } from "../../shared/excel/utils";
import {
  TTranslateFunction,
  formatCurrency,
  formatNumber,
  formatPercentage,
  useExists,
  useTranslation,
} from "../../shared/i18n";
import { LockIcon } from "../../shared/icons";
import { normalizeNumber } from "../../shared/inputs/input";
import { ExpandCell } from "../../shared/table/ExpandCell";
import {
  ILogs,
  IMappingLog,
  LOG_LEVEL_ENUM,
  LOG_TYPE_ENUM,
  deduplicateLogs,
} from "../../shared/types/log.types";
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from "../../shared/utils";
import {
  DIFFERENCE_DISPLAY_MODE_ENUM,
  DIFFERENCE_MODE_ENUM,
  Difference,
  getDifference,
} from "../../shared/visualization/Difference";
import {
  COMPARE_DIFFERENCE_BASES_ENUM,
  DIFFERENCE_POSITION_ENUM,
  ICompareNumberDifference,
} from "../Compare.types";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "../Table.types";

import styles from "./CompareTable.module.scss";
import { ICompareRow } from "./CompareTable.types";
import {
  IRowsDict,
  getBaseValue,
  getCommentFromCellContextOrFalse,
  getDocumentIdFromColumn,
  getFullNomenclature,
  getIsColumnExpanded,
  getNumberDifferencePositionFromThreshold,
  getRowNumber,
  getValueNumberDifference,
  getWorkIdFromColumn,
} from "./CompareTableUtils";

export function DesignationCell(cellContext: CellContext<ICompareRow, any>) {
  const tenders = cellContext.row.original.tenders;
  const designations = Object.values(tenders).map(
    (tender) =>
      `${tender.nomenclatureDocumentId}.${tender.designation} L#${
        tender.row_number + 1
      }`
  );
  return (
    <>
      <ExpandCell cellContext={cellContext} />
      <OverlayTrigger
        placement="bottom"
        overlay={
          Boolean(designations.length) ? (
            <Tooltip>
              {designations.map((designation, index) => (
                <div key={designation + index} className="text-start mb-1">
                  <span>{designation}</span>
                  {index !== designations.length - 1 && <hr className="m-0" />}
                </div>
              ))}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div
          className={classNames(styles["line-hover"], styles["line-offset"])}
        >
          {cellContext.getValue()?.component ?? cellContext.getValue()}
          <span>
            {Boolean(cellContext.row.original.estimate) &&
              ` L#${cellContext.row.original.estimate!.row_number + 1}`}
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}

function InputCell({
  className,
  isEstimate,
  inputWidth = "10ch",
  ...props
}: CellContext<ICompareRow, any> & {
  className?: string;
  isEstimate: boolean;
  inputWidth?: string | number;
}) {
  const { t } = useTranslation("InputCell");
  const editEnabled = [
    true,
    props.column.parent?.columnDef.meta?.dataType,
  ].includes(props.table.options.meta?.editEnabled!);
  const [focus, setFocus] = useState(false);
  const value = props.getValue<number | undefined>();
  const [currentValue, setCurrentValue] = useState(value);

  if (value === undefined) {
    return <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>;
  }

  const documentId = getDocumentIdFromColumn(props.column)!;
  const work_id = getWorkIdFromColumn(props.column)!;

  const columnDataType = props.column.columnDef.meta?.dataType;
  const displayableValue =
    columnDataType === TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
      ? formatNumber(value)
      : formatCurrency(value);

  const isEditable = [
    TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
    TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
  ].includes(columnDataType);

  return (
    <div
      className={classNames(
        {
          "h-100": isEditable && focus,
          "px-2": !(isEditable && focus),
        },
        "fw-bold",
        className
      )}
    >
      {isEditable && focus ? (
        <input
          style={{ width: inputWidth }}
          type="text"
          onChange={({ target }) => {
            setCurrentValue(Number(normalizeNumber(target.value)));
          }}
          className={classNames("py-0 m-0 px-1 h-100")}
          suppressContentEditableWarning
          onClick={(event) => {
            event.stopPropagation();
            setFocus(true);
          }}
          autoFocus
          onBlur={() => {
            setFocus(false);
            const row_number = getRowNumber(
              props.row.original,
              isEstimate,
              documentId
            );
            if (row_number === undefined) {
              console.error("row_number not found");
              return;
            }
            const update =
              columnDataType === TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
                ? props.table.options.meta?.updateQuantity
                : props.table.options.meta?.updateUnitPrice;
            // only update if modified
            if (currentValue !== value) {
              update?.(documentId, work_id, row_number, currentValue || null);
            }
          }}
          onKeyDown={(event) => {
            const { key, currentTarget, shiftKey } = event;
            if (key === "Enter" && !shiftKey) {
              event.preventDefault();
              currentTarget.blur();
            }
            key !== "Escape" && event.stopPropagation();
          }}
          value={currentValue}
        />
      ) : (
        <div
          className={classNames(
            "d-flex",
            styles["input-cell"],
            isEditable && editEnabled && "ms-4"
          )}
        >
          {displayableValue}
          {isEditable && editEnabled && (
            <span
              onClick={(event) => {
                if (editEnabled) {
                  event.stopPropagation();
                  setFocus(true);
                }
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{t("unlock cell to edit")}</Tooltip>}
              >
                <span className={styles["input-cell-lock"]}>
                  <LockIcon />
                </span>
              </OverlayTrigger>
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export function EstimateCell(props: CellContext<ICompareRow, any>) {
  return <InputCell isEstimate={true} {...props} />;
}

export function UnitCell(props: CellContext<ICompareRow, any>) {
  const { t } = useTranslation("CompareTableCell");
  const unitToDisplay = props.getValue<string>();
  const tenders = props.row.original.tenders;
  // only keeps tender with a unit different than the one to display
  const differentTendersUnit = Object.values(tenders).filter(
    (tender) =>
      // if the one to display is empty it'll use a placeholder instead
      (tender.unit?.trim() || DEFAULT_EMPTY_VALUE_PLACEHOLDER).toLowerCase() !==
      unitToDisplay.toLowerCase()
  );

  return differentTendersUnit.length > 0 ? (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {differentTendersUnit.map((tender, index) => (
            <div
              key={tender.nomenclatureDocumentId}
              className="text-start mb-1"
            >
              <span>
                {tender.nomenclatureDocumentId}.{" "}
                {!tender.unit?.trim() ? t("empty") : tender.unit}
              </span>
              {index !== differentTendersUnit.length - 1 && (
                <hr className="m-0" />
              )}
            </div>
          ))}
        </Tooltip>
      }
    >
      <span>
        <LogLevelIconComponent level={LOG_LEVEL_ENUM.WARNING} />
        &nbsp;{unitToDisplay}
      </span>
    </OverlayTrigger>
  ) : (
    unitToDisplay
  );
}

export function TenderCell({
  t,
  ...props
}: CellContext<ICompareRow, any> & { t: TTranslateFunction }) {
  const columnDataType = props.column.columnDef.meta?.dataType;

  const numberDifferenceSettings =
    props.table.options.meta?.compareDifferenceSettings?.number_difference;
  const isNumberDifferenceEnabled = numberDifferenceSettings?.active;
  if (isNumberDifferenceEnabled) {
    const displayableBaseName = Object.values(
      COMPARE_DIFFERENCE_BASES_ENUM
    ).includes(numberDifferenceSettings.base as COMPARE_DIFFERENCE_BASES_ENUM)
      ? t(numberDifferenceSettings.base)
      : props.table.getColumn(numberDifferenceSettings.base)?.columnDef.header;

    const difference =
      getValueNumberDifference(
        columnDataType as
          | TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
          | TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE
          | TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
        props,
        numberDifferenceSettings
      ) ?? 0;
    const differencePosition = getNumberDifferencePositionFromThreshold(
      difference,
      numberDifferenceSettings
    );
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {t("difference with", { base: displayableBaseName })}
            {numberDifferenceSettings.mode ===
            DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT
              ? formatPercentage(difference)
              : formatCurrency(difference)}
          </Tooltip>
        }
      >
        <span>
          <InputCell
            isEstimate={false}
            className={classNames({
              "text-danger":
                differencePosition === DIFFERENCE_POSITION_ENUM.ABOVE,
              "text-info":
                differencePosition === DIFFERENCE_POSITION_ENUM.BELOW,
            })}
            {...props}
          />
        </span>
      </OverlayTrigger>
    );
  }
  return <InputCell isEstimate={false} {...props} />;
}

/**
 * A Cell component meant to be used with CompareTable
 * to display and edit a comment associated with a Document (Tender or Estimate)
 * Needs the following to be present on react-table TableMeta:
 * - commentByRowNumberByDocumentRowIdByDocumentColId
 * - updateComment
 * - estimateId
 */
export function CommentCell(props: CellContext<ICompareRow, any>) {
  const documentId = getDocumentIdFromColumn(props.column);
  const isColumnExpanded = getIsColumnExpanded(props.table, documentId);

  const comment = getCommentFromCellContextOrFalse(props);
  if (!documentId || comment === false) {
    return <div className="text-center">{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</div>;
  }
  const editEnabled = [
    true,
    props.column.parent?.columnDef.meta?.dataType,
  ].includes(props.table.options.meta?.editEnabled!);
  return (
    <pre
      role="textbox"
      className={classNames(
        "p-0 m-0 ps-1 h-100",
        isColumnExpanded && styles.expanded
      )}
      contentEditable={editEnabled}
      suppressContentEditableWarning
      onClick={(event) => {
        if (editEnabled) {
          event.stopPropagation();
        }
      }}
      onBlur={({ target: { innerText } }) => {
        const comment = getCommentFromCellContextOrFalse(props);
        // only update if modified
        if (comment && innerText !== comment.message) {
          props.table.options.meta?.updateComment?.(documentId, {
            ...comment,
            message: innerText ?? "",
          });
        }
      }}
      onKeyDown={(event) => {
        const { key, currentTarget, shiftKey } = event;
        if (key === "Enter" && !shiftKey) {
          event.preventDefault();
          currentTarget.blur();
        }
        key !== "Escape" && event.stopPropagation();
      }}
    >
      {comment.message}
    </pre>
  );
}

export function CommentHeaderCell({
  table,
  column,
}: HeaderContext<ICompareRow, unknown>) {
  const { t } = useTranslation("useCompareTable");

  const documentId = getDocumentIdFromColumn(column);
  const isColumnExpanded = getIsColumnExpanded(table, documentId);
  const ExpandIcon = isColumnExpanded ? CgArrowsMergeAltH : CgArrowsShrinkH;
  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={
        <Tooltip>
          {t(isColumnExpanded ? "contract comments" : "expand comments")}
        </Tooltip>
      }
    >
      <Button
        onClick={() =>
          documentId &&
          table.options.meta?.setExpandedCommentColumnsByDocumentIds?.(
            (comments) => ({ ...comments, [documentId]: !comments[documentId] })
          )
        }
        variant="text"
        className="text-reset d-flex align-items-center w-100 h-100 px-3 py-2 justify-content-center"
      >
        {t("comments")}
        <ExpandIcon className="ms-3" size={21} />
      </Button>
    </OverlayTrigger>
  );
}

export type TDifferenceableDataType =
  | TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
  | TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE
  | TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE;
/** get this estimate row value for this dataType */
export function getComparator(
  row: ICompareRow,
  dataType: TDifferenceableDataType
) {
  return row.estimate?.[dataType];
}

/** get compared tender row value for this dataType */
export function getComparee(
  row: ICompareRow,
  tenderId: string,
  dataType: TDifferenceableDataType
) {
  return row.tenders[tenderId]?.[dataType];
}
export function getDifferenceModeFromDataType(
  dataType: TABLE_COLUMN_DATA_TYPE_ENUM
) {
  switch (dataType) {
    case TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY:
      return DIFFERENCE_MODE_ENUM.PLAIN;
    case TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE:
    case TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE:
      return DIFFERENCE_MODE_ENUM.EURO;
    default:
      return DIFFERENCE_MODE_ENUM.PERCENT;
  }
}

export function DifferenceCell({
  cellContext,
  tenderId,
  comparedDataType,
  numberDifferenceSettings,
}: {
  cellContext: CellContext<ICompareRow, any>;
  tenderId: string;
  comparedDataType: TDifferenceableDataType;
  numberDifferenceSettings: ICompareNumberDifference;
}) {
  const row = cellContext.row.original;
  const comparee = getComparee(row, tenderId, comparedDataType);
  const comparator = getBaseValue(cellContext, comparedDataType);
  return (
    <>
      <Difference
        className="d-block"
        comparee={comparee}
        comparator={comparator}
        mode={
          numberDifferenceSettings.mode === DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT
            ? DIFFERENCE_MODE_ENUM.PERCENT
            : getDifferenceModeFromDataType(comparedDataType)
        }
      />
    </>
  );
}

export function LogsCell({
  value,
  dict,
  otherDict,
}: {
  value?: ILogs;
  dict: IRowsDict;
  otherDict: IRowsDict;
}) {
  if (value === undefined) {
    return null;
  }
  return <LogsComponent logs={value} dict={dict} otherDict={otherDict} />;
}

export function LogsComponent({
  logs,
  dict,
  otherDict,
}: {
  logs: ILogs;
  dict: IRowsDict;
  otherDict: IRowsDict;
}) {
  // true to force show ToolTip, undefined to allow it to be shown on hover (false forcefully hides it)
  const [showTooltip, setShowTooltip] = useState<boolean | undefined>();
  return (
    <>
      <OverlayTrigger
        trigger={["click", "hover", "focus"]}
        show={showTooltip}
        placement="bottom"
        overlay={
          <Tooltip
            onClick={(e) => {
              // to ease copy-pasting
              e.stopPropagation();
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(undefined)}
          >
            <ul
              className={classNames(
                "ps-0 mt-2 overflow-auto max-vh-50",
                styles["log-categories"]
              )}
            >
              {[logs.ERROR, logs.WARNING, logs.INFO]
                .filter((logs) => logs?.length)
                .map(deduplicateLogs)
                .map((logs) => {
                  return (
                    <li key={logs[0].level} className="list-group-item">
                      <LogMessageComponent
                        logs={logs}
                        dict={dict}
                        otherDict={otherDict}
                      />
                    </li>
                  );
                })}
            </ul>
          </Tooltip>
        }
      >
        <div className={classNames("m-1", styles["stack-icons"])}>
          {Boolean(logs[LOG_LEVEL_ENUM.INFO]?.length) && (
            <LogLevelIconComponent level={LOG_LEVEL_ENUM.INFO} />
          )}
          {Boolean(logs[LOG_LEVEL_ENUM.WARNING]?.length) && (
            <LogLevelIconComponent level={LOG_LEVEL_ENUM.WARNING} />
          )}
          {Boolean(logs[LOG_LEVEL_ENUM.ERROR]?.length) && (
            <LogLevelIconComponent level={LOG_LEVEL_ENUM.ERROR} />
          )}
        </div>
      </OverlayTrigger>
    </>
  );
}

export function getDifferenceColumn(
  tenderId: string,
  comparedDataType: TDifferenceableDataType,
  numberDifferenceSettings: ICompareNumberDifference,
  t: TTranslateFunction
): ColumnDef<ICompareRow> {
  return {
    header: t("difference"),
    // accessorFn value serves here for Excel export only
    accessorFn: (row) => {
      const comparee = getComparee(row, tenderId, comparedDataType);
      const comparator = getComparator(row, comparedDataType);
      const modeForDataType = getDifferenceModeFromDataType(comparedDataType);
      const difference = getDifference(
        comparee,
        comparator,
        numberDifferenceSettings.mode === DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT
          ? DIFFERENCE_MODE_ENUM.PERCENT
          : modeForDataType
      );
      return difference;
    },
    id: `tenders.${tenderId}.${comparedDataType}.${TABLE_COLUMN_DATA_TYPE_ENUM.DIFFERENCE}`,
    cell: (cellContext: CellContext<ICompareRow, any>) => (
      <DifferenceCell
        cellContext={cellContext}
        tenderId={tenderId}
        comparedDataType={comparedDataType}
        numberDifferenceSettings={numberDifferenceSettings}
      />
    ),
    meta: {
      className: "text-center fs-6",
      excel: { width: DIFFERENCE_COL_WIDTH },
      dataType: TABLE_COLUMN_DATA_TYPE_ENUM.DIFFERENCE,
    },
  };
}
export function LogLevelIconComponent({
  level,
  size = 20,
  className,
}: {
  level: LOG_LEVEL_ENUM;
  size?: number;
  className?: string;
}) {
  switch (level) {
    case LOG_LEVEL_ENUM.INFO:
      return (
        <FaCircleInfo size={size} color={colors.info} className={className} />
      );
    case LOG_LEVEL_ENUM.WARNING:
      return (
        <FaTriangleExclamation
          size={size}
          color={colors.warning}
          className={className}
        />
      );
    case LOG_LEVEL_ENUM.ERROR:
      return (
        <FaXmark size={size} color={colors.danger} className={className} />
      );
    default:
      console.error("invalid LogLevel: ", level);
      return null;
  }
}

export function LogMessageComponent({
  logs,
  dict,
  otherDict,
}: {
  logs: IMappingLog[];
  dict: IRowsDict;
  otherDict: IRowsDict;
}) {
  const { exists } = useExists("Logs");

  if (!logs.length) {
    return null;
  }
  return (
    <ul>
      {logs.map((log, index) => {
        const mappedLog = logLineMapper(log, dict, otherDict);
        return (
          <li
            key={mappedLog.message + index}
            className="text-start mb-1 list-group-item"
          >
            <LogLevelIconComponent
              level={mappedLog.level}
              size={16}
              className="position-absolute end-100 me-2"
            />
            <span>
              <ContactSupportOnError
                i18nKey={`Logs.${
                  exists(mappedLog.type) ? mappedLog.type : "unknown message"
                }`}
                error={mappedLog}
                values={mappedLog}
              />
            </span>
          </li>
        );
      })}
    </ul>
  );
}

/**
 * map logs based on type
 * map line numbers to display to be 1 indexed to stay consistent with excel
 * the parameters are an array of strings, different for each LogType
 */
function logLineMapper(
  log: IMappingLog,
  dict: IRowsDict,
  otherDict: IRowsDict
) {
  if (log.parameters) {
    const [rowNumber, otherRowNumber] = log.parameters;
    let row: ICompareRow;
    switch (log.type) {
      case LOG_TYPE_ENUM.DUPLICATE_LINE:
      case LOG_TYPE_ENUM.LINE_ERASED:
      case LOG_TYPE_ENUM.UNITS_DIFFERENT:
      case LOG_TYPE_ENUM.DUPLICATE_ID:
      case LOG_TYPE_ENUM.DUPLICATE_DESIGNATION:
      case LOG_TYPE_ENUM.POTENTIAL_MATCH:
      case LOG_TYPE_ENUM.POTENTIAL_MATCH_NUMBER:
        row = dict[Number(rowNumber)];
        return {
          ...log,
          parameters: [
            mapRowNumberOneIndexAndNomenclature(rowNumber, row),
            ...log.parameters.slice(1),
          ],
        };
      case LOG_TYPE_ENUM.NODE_ADOPTION:
      case LOG_TYPE_ENUM.VIRTUAL_NODE_ADOPTION:
        row = dict[rowNumber];
        const otherRow = Object.is(Number(otherRowNumber), NaN)
          ? undefined
          : otherDict[Number(otherRowNumber)];
        return {
          ...log,
          parameters: [
            mapRowNumberOneIndexAndNomenclature(rowNumber, row),
            mapRowNumberOneIndexAndNomenclature(otherRowNumber, otherRow),
            ...log.parameters.slice(2),
          ],
        };
      case LOG_TYPE_ENUM.COLUMN_TYPE:
        // test which threshold is lower: invalid or empty
        if (log.parameters[2] < log.parameters[3]) {
          return {
            ...log,
            type: LOG_TYPE_ENUM.COLUMN_TYPE_INVALID,
            parameters: mapColumnTypeNumberToExcelHeader(log.parameters),
          };
        } else {
          return {
            ...log,
            type: LOG_TYPE_ENUM.COLUMN_TYPE_EMPTY,
            parameters: mapColumnTypeNumberToExcelHeader(log.parameters),
          };
        }
      case LOG_TYPE_ENUM.MISSING_WORK_ID:
      default:
        return log;
    }
  } else {
    // avoids displaying {{paramaters.x}} if no parameters are present
    log.parameters = ["", "", "", "", ""];
  }
  return log;
}

function mapRowNumberOneIndexAndNomenclature(
  rowNumber: string,
  row?: ICompareRow
) {
  return `${Number(rowNumber) + 1}${
    row
      ? ` (${getFullNomenclature({
          getValue: () => row.nomenclature,
          row,
        } as any)})`
      : ""
  }`;
}

/**
 * Maps columnType number to Excel Header
 * @param parameters string[] parameters of the IMappingLog
 * @returns mapped parameters string[]
 */
function mapColumnTypeNumberToExcelHeader(parameters: string[]) {
  const [columnsString, ...rest] = parameters;
  const columns = JSON.parse(columnsString);

  return [columns.map(getExcelTypeHeader), ...rest];
}
