import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FiFilter } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { listRowTypesToObjectMapper } from "../Mapping/ClassificationTable";
import { createOrUpdatePriceAveragePanel } from "../api/fetchPriceAverage";
import {
  fetchMappedTender,
  fetchTenderMappedPriceAverages,
} from "../api/fetchTenders";
import { useModal } from "../shared/DefaultModal";
import { ExportTableToExcel } from "../shared/excel/ExportTableToExcel";
import { Footer } from "../shared/footer";
import { Header } from "../shared/header";
import { useEnumTranslation, useTranslation } from "../shared/i18n";
import { CogIcon, SaveIcon } from "../shared/icons";
import {
  IPriceAveragePanel,
  priceAveragePanelToPriceAveragePanelDTOMapper,
} from "../shared/types/averagePrice.types";
import { ROWTYPE_USAGE_ENUM } from "../shared/types/rowType.types";
import { IMappedTender } from "../shared/types/tender.types";
import { useHasChanged } from "../shared/useHasChanged";
import {
  QUERY_KEYS_ENUM,
  useConstructionIndicatorMultipliersQuery,
  usePriceAveragePanelQuery,
  useRowTypesQuery,
} from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { applyConstructionIndicatorMultipliers } from "./ConstructionIndicatorApplier";
import {
  getCellAlignment,
  getTenderCellFontColor,
  mapCell,
} from "./ExportPriceAverageTable";
import { PriceAverageFiltersModal } from "./PriceAverageFiltersModal";
import { PriceAveragePanelEditModal } from "./PriceAveragePanelModal";
import { PriceAverageSettingsModal } from "./PriceAverageSettingsModal";
import { defaultPriceAverageSettings } from "./PriceAverageSettingsModal";
import { PriceAverageTable } from "./PriceAverageTable";
import {
  IPriceAverageSettings,
  IPriceAverageTableMeta,
} from "./PriceAverageTable.types";
import {
  IGNORE_ENTIRE_TENDER,
  isEntireTenderIgnored,
  usePriceAverageTable,
} from "./usePriceAverageTable";

export function PriceAveragePage() {
  const { t } = useTranslation("PriceAverage");
  const { tEnum } = useEnumTranslation();

  const { priceAveragePanelId } = useParams();
  const [tenders, setTenders] = useState<IMappedTender[]>();
  const [rerender, forceRerender] = useState({});

  const {
    show: showSaveModal,
    handleClose: handleCloseSaveModal,
    handleShow: handleShowSaveModal,
  } = useModal("ComparisonSaveModal");
  const [isDirty, setIsDirty] = useState(false);

  const priceAveragePanel = usePriceAveragePanelQuery(priceAveragePanelId);
  const rowTypes = useRowTypesQuery(
    priceAveragePanel.data?.settings?.filters?.conventional_allotments,
    true,
    ROWTYPE_USAGE_ENUM.DPGF
  );
  // map list of rowTypes to dict to be used by Table as data
  const classification_dict = useMemo(
    () => listRowTypesToObjectMapper(rowTypes.data),
    [rowTypes.data]
  );

  // on first filters modal closing we want to save the filters immediately
  const initialFiltersAreUndefined = !priceAveragePanel.data?.settings?.filters;

  // we want to pre-open the filters modal on page load if no filters have been saved yet
  const [showFiltersFirstOpening, setShowFiltersFirstOpening] = useState(
    initialFiltersAreUndefined
  );
  if (useHasChanged(priceAveragePanel.data || showFiltersFirstOpening)) {
    setShowFiltersFirstOpening(initialFiltersAreUndefined);
  }

  const constructionIndicatorMultipliers =
    useConstructionIndicatorMultipliersQuery();

  const tenderQueries = useQueries({
    queries:
      priceAveragePanel.data?.price_breakdowns?.map((tender) => ({
        queryKey: [QUERY_KEYS_ENUM.TENDER, tender],
        queryFn: () => fetchMappedTender(tender),
      })) ?? [],
  });
  const tenderMappedPriceAverageQueriesData = useQueries({
    queries:
      priceAveragePanel.data?.price_breakdowns?.map((tender) => ({
        queryKey: [QUERY_KEYS_ENUM.TENDER_MAPPED_PRICE_AVERAGES, tender],
        queryFn: () => fetchTenderMappedPriceAverages(tender),
      })) ?? [],
  });

  const isLoading =
    rowTypes.isFetching ||
    priceAveragePanel.isLoading ||
    tenderQueries.some((query) => query.isLoading) ||
    tenderMappedPriceAverageQueriesData.some((query) => query.isLoading) ||
    constructionIndicatorMultipliers.isLoading;

  const tenderQueriesData = tenderQueries
    .filter((query) => !query.error)
    .map((query) => query.data);
  const mappedTenderMappedPriceAveragesQueries =
    tenderMappedPriceAverageQueriesData.map((query) => query.data);
  // Initialize tenders when all data is loaded or changed
  if (
    useHasChanged(
      ...tenderQueriesData,
      constructionIndicatorMultipliers.data,
      rowTypes.data,
      isLoading
    ) &&
    Boolean(constructionIndicatorMultipliers.data) &&
    tenderQueriesData.every(Boolean) &&
    Boolean(constructionIndicatorMultipliers.data) &&
    Boolean(rowTypes.data) &&
    !isLoading
  ) {
    setTenders(
      tenderQueriesData
        .map((tender, index) =>
          applyConstructionIndicatorMultipliers(
            {
              ...tender!,
              mapped_price_averages:
                mappedTenderMappedPriceAveragesQueries[index],
            },
            constructionIndicatorMultipliers.data!,
            classification_dict
          )
        )
        .map((tender) => ({
          ...tender,
          ignored: isEntireTenderIgnored(tender, priceAveragePanel.data),
        }))
    );
  }

  const queryClient = useQueryClient();

  /**
   * Adds or Removes the referenceCode of the tender from the price calculation
   *
   * @param tenderId id of the tender to toggle
   * @param referenceCode referenceCode to toggle
   * @param add should add or not
   */
  const addOrRemoveTenderReferenceCodeFromCalculation = useCallback(
    (tenderId: string, referenceCode: string, add?: boolean) => {
      const priceAveragePanelData = priceAveragePanel.data!;
      let added = add;

      priceAveragePanelData.ignored_classifications =
        priceAveragePanelData.ignored_classifications ?? {};
      const ignored_classifications =
        priceAveragePanelData.ignored_classifications;
      ignored_classifications[tenderId] =
        ignored_classifications[tenderId] ?? [];
      if (ignored_classifications[tenderId].includes(referenceCode)) {
        if (add !== true) {
          ignored_classifications[tenderId] = ignored_classifications[
            tenderId
          ].filter((r) => r !== referenceCode);
          added = false;
        }
      } else {
        if (add !== false) {
          ignored_classifications[tenderId].push(referenceCode);
          added = true;
        }
      }
      queryClient.setQueryData(
        [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanelId!],
        priceAveragePanelData
      );
      setIsDirty(true);
      forceRerender({});
      return added;
    },
    [priceAveragePanel.data, priceAveragePanelId, queryClient]
  );

  const {
    show: showFilters,
    handleClose: handleCloseFilters,
    handleShow: handleShowFilters,
  } = useModal("PriceAverageFilters");
  const {
    show: showSettings,
    handleClose: handleCloseSettings,
    handleShow: handleShowSettings,
  } = useModal("PriceAverageSettings");

  /**
   * Adds or Removes the tender from the price calculation
   *
   * @param id id of the tender to toggle
   * @param ignored if the tender should be ignored or not
   */
  const addOrRemoveTenderFromCalculation = useCallback(
    (id: string, ignored: boolean) => {
      queryClient.setQueryData(
        [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanelId!],
        (old_placeholder: any) => {
          // make sure there are no JS object references left to avoid side effects
          const old: IPriceAveragePanel | undefined = JSON.parse(
            JSON.stringify(old_placeholder)
          );
          const allIgnoredClassifications = old?.ignored_classifications ?? {};
          const tenderIgnoredClassifications =
            allIgnoredClassifications[id] ?? [];
          if (ignored) {
            tenderIgnoredClassifications.push(IGNORE_ENTIRE_TENDER);
          } else {
            const ignoredClassificationIndex =
              // take tender into account again for price calculation by removing IGNORE_ENTIRE_TENDER
              tenderIgnoredClassifications.findIndex(
                (classification) => classification === IGNORE_ENTIRE_TENDER
              );
            if (ignoredClassificationIndex !== -1) {
              tenderIgnoredClassifications.splice(
                ignoredClassificationIndex,
                1
              );
            }
          }
          allIgnoredClassifications[id] = tenderIgnoredClassifications;
          const priceAveragePanelData = {
            ...old,
            ignored_classifications: allIgnoredClassifications,
          };

          return priceAveragePanelData;
        }
      );
      forceRerender({});
      setIsDirty(true);
    },
    [priceAveragePanelId, queryClient]
  );

  const meta = useMemo<IPriceAverageTableMeta>(
    () => ({
      priceAverageSettings: {
        ...defaultPriceAverageSettings,
        ...(priceAveragePanel.data?.settings ?? {}),
      },
    }),
    [priceAveragePanel.data?.settings]
  );

  const table = usePriceAverageTable(
    classification_dict,
    rerender,
    meta,
    tenders,
    priceAveragePanel.data,
    addOrRemoveTenderFromCalculation
  );

  const priceAveragePanelCreation = useMutation({
    mutationFn: createOrUpdatePriceAveragePanel,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS]);
    },
  });
  const onPriceAveragePanelFiltersSubmit = useCallback(
    (panel: Partial<IPriceAveragePanel>) => {
      if (initialFiltersAreUndefined) {
        priceAveragePanelCreation.mutate(
          priceAveragePanelToPriceAveragePanelDTOMapper(panel)
        );
        setShowFiltersFirstOpening(false);
      } else {
        setIsDirty(true);
      }
      forceRerender({});
      // force refresh
      queryClient.setQueryData(
        [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanelId!],
        undefined
      );
      queryClient.setQueryData(
        [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanelId!],
        panel
      );
      handleCloseFilters();
    },
    [
      handleCloseFilters,
      initialFiltersAreUndefined,
      priceAveragePanelCreation,
      priceAveragePanelId,
      queryClient,
    ]
  );

  return (
    <div className="min-h-100 max-h-100 d-flex flex-column">
      <Header />
      {priceAveragePanel.data && (
        <PriceAverageFiltersModal
          show={
            showFilters ||
            (!priceAveragePanel.isLoading && showFiltersFirstOpening)
          }
          handleClose={() => {
            setShowFiltersFirstOpening(false);
            handleCloseFilters();
          }}
          priceAveragePanel={priceAveragePanel.data}
          onSubmit={onPriceAveragePanelFiltersSubmit}
        />
      )}
      <PriceAverageSettingsModal
        show={showSettings}
        handleClose={handleCloseSettings}
        settings={priceAveragePanel.data?.settings as IPriceAverageSettings}
        onSubmit={(settings) => {
          setIsDirty(true);
          forceRerender({});
          queryClient.setQueryData(
            [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanelId!],
            {
              ...priceAveragePanel.data,
              settings: {
                ...(priceAveragePanel.data?.settings ?? {}),
                ...settings,
              },
            }
          );
          handleCloseSettings();
        }}
      />
      <PriceAveragePanelEditModal
        show={showSaveModal}
        handleClose={(priceAveragePanel) => {
          handleCloseSaveModal();
          if (priceAveragePanel) {
            setIsDirty(false);
          }
        }}
        askWhenOverwriting
        priceAveragePanel={priceAveragePanel.data}
      />
      <Container fluid>
        <Row className="m-0 my-3">
          <Col className="align-self-center">
            <h6>
              {priceAveragePanel.data?.name}
              <small className="fw-normal ms-2">
                (
                {tEnum(
                  priceAveragePanel.data?.settings?.filters
                    ?.conventional_allotments ?? "STRUCTURAL_WORK"
                )}
                )
              </small>
            </h6>
          </Col>
          <Col xs="auto" className="align-self-center">
            {t("price indexed", {
              date: constructionIndicatorMultipliers.data?.dates?.slice(-1),
            })}
          </Col>
          <Col xs="auto" className="align-self-center">
            <Button
              variant="outline-secondary"
              className="rounded"
              onClick={handleShowFilters}
              disabled={priceAveragePanel.isLoading || !priceAveragePanel.data}
              size="sm"
              data-test={ifTest("filters-button")}
            >
              <FiFilter className="me-2" size={21} /> {t("filters")}
            </Button>
          </Col>
          <Col xs="auto" className="align-self-center">
            <Button
              variant="outline-secondary"
              className="rounded"
              onClick={handleShowSettings}
              disabled={priceAveragePanel.isLoading || !priceAveragePanel.data}
              size="sm"
              data-test={ifTest("settings-button")}
            >
              <CogIcon className="me-2" /> {t("settings")}
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              onClick={handleShowSaveModal}
              disabled={!isDirty || isLoading}
              size="lg"
              className="rounded"
              data-test={ifTest("save-price-average-panel-button")}
            >
              <SaveIcon className="me-2" />
              {t("save")}
            </Button>
          </Col>
          <Col xs="auto">
            <ExportTableToExcel
              table={table}
              isLoading={
                isLoading ||
                !priceAveragePanel.data?.settings?.filters
                  ?.conventional_allotments
              }
              fileName={`${t("price_average")}_${
                priceAveragePanel.data?.name
              }_${
                priceAveragePanel.data?.settings?.filters
                  ?.conventional_allotments
              }`}
              sheetName={t("price_average")}
              mapCell={mapCell}
              getCellAlignment={getCellAlignment}
              getFontColor={getTenderCellFontColor}
            />
          </Col>
        </Row>
      </Container>
      <PriceAverageTable
        table={table}
        isLoading={isLoading}
        priceAveragePanel={priceAveragePanel.data}
        addOrRemoveTenderReferenceCodeFromCalculation={
          addOrRemoveTenderReferenceCodeFromCalculation
        }
        tenders={tenders}
      />
      <Footer />
    </div>
  );
}
