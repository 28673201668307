import { Table as ReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import React from "react";

import { Table } from "../../shared/table";
import { cancelEvent } from "../../shared/utils";
import {
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "../Table.types";

import styles from "./CompareTable.module.scss";
import { ICompareRow } from "./CompareTable.types";
import { cellIsLineDifference, getRowDepth } from "./CompareTableUtils";

export function CompareTable({
  table,
  isLoading,
}: {
  table: ReactTable<ICompareRow>;
  isLoading?: boolean;
}) {
  // has data?
  const hasRows = table.getRowModel().rows.length > 0;

  return (
    <Table
      table={table}
      className={classNames(
        styles.table,
        styles["table-empty-first-header"],
        styles["border-first-line"]
      )}
      getColumnHeaderClassName={() => classNames(!hasRows && "d-none")}
      getRowClassName={(row) => {
        const isCategory = Boolean(row.subRows?.length);
        return classNames(
          isCategory && styles["category-row"],
          styles[
            `level-${Math.min(
              row.original.meta?.level ?? 0,
              MAX_SUPPORTED_DEPTH
            )}`
          ],
          styles[`depth-${getRowDepth(table, row)}`],
          `pricenode-${row.id}`
        );
      }}
      getCellClassName={(cell, columnIndex, row) => {
        return classNames(
          cellIsLineDifference(cell, row) && styles["line-difference"]
        );
      }}
      onCellClick={(cell, columnIndex, row, rowIndex, event) => {
        if (
          cell.column.columnDef.meta?.dataType ===
          TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS
        ) {
          cancelEvent(event);
        }
      }}
      noPagination
      stickyHeaders
      isLoading={isLoading}
      disableColumnSizing
    />
  );
}
