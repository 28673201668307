import {
  CellContext,
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useMemo, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

import { rowTypesObjectToRowsMapper } from "../Mapping/ClassificationTable";
import { IClassification } from "../Mapping/mapping.types";
import { DIFFERENCE_POSITION_ENUM } from "../compare/Compare.types";
import styles from "../compare/CompareTable/CompareTable.module.scss";
import { getNumberDifferencePositionFromThreshold } from "../compare/CompareTable/CompareTableUtils";
import { applyRowMeta } from "../compare/CompareTable/useCompareTable";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "../compare/Table.types";
import { DESCRIPTION_COL_WIDTH } from "../shared/excel/ExportTableToExcel";
import {
  TTranslateFunction,
  formatCurrency,
  formatPercentage,
  useEnumTranslation,
  useTranslation,
} from "../shared/i18n";
import { CheckBox } from "../shared/inputs";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { ExpandCell } from "../shared/table/ExpandCell";
import { ExpandHeader } from "../shared/table/ExpandHeader";
import {
  IPriceAveragePanel,
  IPriceAverageRow,
} from "../shared/types/averagePrice.types";
import { IMappedTender } from "../shared/types/tender.types";
import { useHasChanged } from "../shared/useHasChanged";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  ifTest,
  isDevelopmentEnv,
  setNested,
} from "../shared/utils";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../shared/visualization/Difference";
import { Percentage } from "../shared/visualization/Percentage";

import { PriceAverageCell } from "./PriceAverageCell";
import priceAverageTableStyles from "./PriceAverageTable.module.scss";
import {
  IPriceAverageTableMeta,
  PriceAverageMode,
} from "./PriceAverageTable.types";
import {
  getExpandedContainsNestedPrices,
  getValueNumberDifference,
} from "./PriceAverageUtils";

function getRowId(originalRow: IPriceAverageRow) {
  return `classification-${originalRow.ref}`;
}

export function usePriceAverageTable(
  classification_dict: IClassification,
  rerender: object,
  meta: IPriceAverageTableMeta,
  tenders?: IMappedTender[],
  priceAveragePanel?: IPriceAveragePanel,
  onTenderIgnoredChange?: (id: string, ignored: boolean) => void
) {
  const { t } = useTranslation("PriceAverageTable");
  const { tEnum } = useEnumTranslation();

  const [expanded, setExpanded] = useState<ExpandedState>(true);
  const isCompletelyExpanded = expanded === true;

  const [classificationRowsMeta, maxDepth] = useMemo(() => {
    const classificationTendersDict = applyTendersToClassificationDict(
      // deep copy to keep the original classification_dict intact
      JSON.parse(JSON.stringify(classification_dict)),
      tenders
    );
    const { classificationRows, maxDepth } =
      rowTypesObjectToRowsMapper<IPriceAverageRow>(classificationTendersDict);
    const classificationRowsMeta = applyRowMeta<IPriceAverageRow>(
      classificationRows,
      maxDepth + 1,
      {} as any,
      [],
      0
    );
    return [classificationRowsMeta, maxDepth];
    //rerender needed since classification_dict change detection is too complicated, gives the parent the ability to force rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification_dict, tenders, rerender]);

  // On tenders change, set the initial expanded state so that all rows having prices are visible
  if (useHasChanged(tenders)) {
    setExpanded(
      getExpandedContainsNestedPrices(
        expanded,
        classificationRowsMeta,
        getRowId
      )
    );
  }

  const rowTypeColumns: ColumnDef<IPriceAverageRow>[] = useMemo(() => {
    const columns: ColumnDef<IPriceAverageRow>[] = [
      {
        header: () => (
          <div className="justify-content-center d-flex flex-column">
            <span>
              <ExpandHeader
                value={t("construction_index")}
                isCompletelyExpanded={isCompletelyExpanded}
                setExpanded={setExpanded}
              />
            </span>
          </div>
        ),
        id: "construction_index",
        accessorKey: "construction_index",
        cell: (cellContext) => (
          <>
            <ExpandCell cellContext={cellContext} />
            <span className={priceAverageTableStyles.offset}>
              {cellContext.getValue<string>()}
            </span>
          </>
        ),
        meta: {
          className: classNames(
            priceAverageTableStyles["index-cell"],
            priceAverageTableStyles["sticky-column"],
            "position-relative vertical-align-middle"
          ),
          excel: {
            header: t("construction_index"),
          },
        },
      },
      {
        header: () => (
          <div className="justify-content-center d-flex flex-column">
            {t("ref")}
          </div>
        ),
        id: "ref",
        accessorKey: "ref",
        meta: {
          className: classNames(
            priceAverageTableStyles["ref-cell"],
            priceAverageTableStyles["sticky-column"],
            "position-relative vertical-align-middle"
          ),
          excel: { width: (maxDepth + 1.5) * 4, header: t("ref") },
        },
      },
      {
        header: () => (
          <div className="justify-content-center d-flex flex-column">
            {t("description")}
          </div>
        ),
        accessorKey: "description",
        id: "description",
        cell: (cellContext) => (
          <div
            className={classNames(
              styles["line-offset"],
              priceAverageTableStyles["line-offset"]
            )}
          >
            {cellContext.getValue<string>()}
          </div>
        ),
        meta: {
          className: classNames(
            styles["designation-cell"],
            priceAverageTableStyles["designation-cell"],
            priceAverageTableStyles["sticky-column"],
            "position-relative"
          ),
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.REFERENCE,
          excel: { width: DESCRIPTION_COL_WIDTH, header: t("description") },
        },
      },
      {
        header: () => (
          <div className="justify-content-center d-flex flex-column">
            {t("unit")}
          </div>
        ),
        accessorKey: "unit",
        id: "unit",
        meta: {
          className: classNames(
            priceAverageTableStyles["unit-cell"],
            priceAverageTableStyles["last-cell"],
            priceAverageTableStyles["sticky-column"]
          ),
          excel: {
            header: t("unit"),
          },
        },
      },
      {
        header: () => (
          <div className="justify-content-center d-flex flex-column">
            {tEnum(meta.priceAverageSettings.mode)}
          </div>
        ),
        accessorKey: "average",
        // compute the "average" (or any other mode) from all active tenders
        accessorFn: (row) =>
          reduceTenderPrices(
            Object.entries(row.tenders ?? {}).filter(
              ([tenderId]) =>
                tenders?.find((tender) => tender.id === tenderId)?.ignored !==
                  true &&
                priceAveragePanel?.ignored_classifications[tenderId]?.includes(
                  row.ref
                ) !== true
            ),
            meta.priceAverageSettings.mode
          ) || undefined,
        cell: PriceAverageCell,
        id: "average",
        meta: {
          className: classNames(
            "text-center",
            priceAverageTableStyles["average-cell"],
            priceAverageTableStyles["sticky-column"],
            priceAverageTableStyles["no-text-selection"]
          ),
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.PRICE_AVERAGE,
          excel: {
            header: t("average"),
          },
        },
      },
    ];
    const filteredTenders = tenders
      ?.filter(Boolean)
      // sort tenders to have ignored ones at the end
      .sort((a, b) => Number(a.ignored ?? false) - Number(b.ignored ?? false));
    filteredTenders?.forEach((tender, index) => {
      columns.push({
        header: () => (
          <TenderHeader
            tender={tender}
            onChange={(ignored) => onTenderIgnoredChange?.(tender.id, ignored)}
          />
        ),
        // avoid "the deeply nested key returned undefined" warnings by using an accessorFn
        accessorFn: (row) => row.tenders?.[tender.id],
        id: `tenders.${tender.id}`,
        cell: (props: CellContext<IPriceAverageRow, any>) => (
          <span data-test={ifTest(`tender-cell-${index}`)}>
            <TenderCell t={t} {...props} />
          </span>
        ),
        meta: {
          className: classNames(
            priceAverageTableStyles["no-text-selection"],
            priceAverageTableStyles["tender-cell"],
            {
              [priceAverageTableStyles["first-cell"]]: index === 0,
              [priceAverageTableStyles["last-cell"]]:
                index === filteredTenders.length - 1,
              [priceAverageTableStyles["ignored-cell"]]: tender.ignored,
              [priceAverageTableStyles["ignored-tender"]]: tender.ignored,
            }
          ),
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.TENDER_PRICE_AVERAGE,
          tenderId: tender.id,
          excel: {
            skip: true,
            header: tenderLabel(tender).replaceAll(" ", "\u00A0"),
          },
        },
      });
    });

    return columns;
  }, [
    t,
    tEnum,
    maxDepth,
    tenders,
    isCompletelyExpanded,
    meta.priceAverageSettings.mode,
    priceAveragePanel,
    onTenderIgnoredChange,
  ]);

  const classificationRowsMetaTrimmed = useMemo(
    () => lTrimClassificationLevels(classificationRowsMeta),
    [classificationRowsMeta]
  );

  const table = useReactTable<IPriceAverageRow>({
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    columns: rowTypeColumns,
    data: classificationRowsMetaTrimmed,
    debugTable: isDevelopmentEnv,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row: IPriceAverageRow) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    enableSorting: false,
    meta: {
      ...meta,
      maxDepth: maxDepth + 1,
      priceAveragePanel,
    },
  });

  return table;
}

const TRIM_CLASSIFICATION_LEVELS = 3;

/** remove ${levels} root rows and extract subRows*/
function lTrimClassificationLevels(
  classificationRowsMeta: IPriceAverageRow[],
  levels = TRIM_CLASSIFICATION_LEVELS
) {
  while (levels > 0) {
    levels--;
    classificationRowsMeta = classificationRowsMeta.flatMap(
      (row) => row.subRows ?? []
    );
  }
  return classificationRowsMeta;
}

function reduceTenderPrices(
  prices: [tender: string, priceAverage: number][],
  mode: PriceAverageMode
) {
  switch (mode) {
    case PriceAverageMode.PRICE_AVERAGE:
      return prices.reduce((a, [, priceAverage], _, array) => {
        return a + priceAverage / array.length;
      }, 0);
    case PriceAverageMode.PRICE_MIN:
      return prices.length
        ? Math.min(...prices.map(([, price]) => price))
        : undefined;
    case PriceAverageMode.PRICE_MAX:
      return prices.length
        ? Math.max(...prices.map(([, price]) => price))
        : undefined;
    case PriceAverageMode.PRICE_MEDIAN:
      const mid = Math.floor(prices.length / 2);
      const sortedPrices = prices
        .map(([, price]) => price)
        .sort((a, b) => a - b);
      return prices.length % 2 !== 0
        ? sortedPrices[mid]
        : (sortedPrices[mid - 1] + sortedPrices[mid]) / 2;
  }
}

export function TenderCell({
  t,
  ...props
}: CellContext<IPriceAverageRow, any> & { t: TTranslateFunction }) {
  const value = props.getValue<number | undefined>();

  if (value === undefined) {
    return <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>;
  }

  const displayableValue = formatCurrency(value);
  const numberDifferenceSettings =
    props.table.options.meta?.priceAverageSettings?.number_difference;
  const mode = props.table.options.meta?.priceAverageSettings?.mode;
  const isNumberDifferenceEnabled = numberDifferenceSettings?.active;
  if (isNumberDifferenceEnabled) {
    const difference = getValueNumberDifference(
      props,
      numberDifferenceSettings
    );
    if (difference === undefined || isNaN(difference)) {
      return <>{displayableValue}</>;
    }
    const differencePosition = getNumberDifferencePositionFromThreshold(
      difference,
      numberDifferenceSettings
    );
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {t("difference with", { base: mode })}
            {numberDifferenceSettings.mode ===
            DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT
              ? formatPercentage(difference)
              : formatCurrency(difference)}
          </Tooltip>
        }
      >
        <span
          className={classNames(
            {
              "text-danger":
                differencePosition === DIFFERENCE_POSITION_ENUM.ABOVE,
              "text-info":
                differencePosition === DIFFERENCE_POSITION_ENUM.BELOW,
            },
            "fw-bold"
          )}
        >
          {displayableValue}
        </span>
      </OverlayTrigger>
    );
  }
  return <>{displayableValue}</>;
}

function TenderHeader({
  tender,
  onChange,
}: {
  tender: IMappedTender;
  onChange?: (ignored: boolean) => void;
}) {
  const [ignored, setIgnored] = useState(tender.ignored);
  const { t } = useTranslation("PriceAverageTable");
  return (
    <div>
      <CheckBox
        className="d-inline me-2"
        checked={!ignored}
        onChange={(e) => {
          const ignored = !e.target.checked;
          tender.ignored = ignored;
          setIgnored(ignored);
          onChange?.(ignored);
        }}
      />
      <Percentage
        tooltip={t("% of lines have been mapped", {
          percentage: formatPercentage(
            tender.row_type_mapping_percentage,
            "never"
          ),
        })}
        style={{ top: 2 }}
        percentage={tender.row_type_mapping_percentage!}
      />
      &ensp;
      <Link
        to={`/${PATH_NAMES_ENUM.OPERATIONS}/${tender.project}/${PATH_NAMES_ENUM.LOTS}/${tender.allotment}/${PATH_NAMES_ENUM.OFFERS}`}
        target="_blank"
        rel="noreferrer"
        className="text-white position-relative"
        style={{ top: 2 }}
      >
        {tenderLabel(tender)}
      </Link>
    </div>
  );
}

export function tenderLabel(tender: IMappedTender) {
  return `${tender.project_name} | ${
    tender.allotment_name && `${tender.allotment_name} | `
  } ${tender.company_name}`;
}

/**
 * Apply the price averages from each tender to the dict
 *
 * @param dict
 * @param tenders list of tenders
 * @returns the dict with the applied prices
 * @example
 * applyTendersToClassificationDict({GOE: {}}, [{price_averages:[{id:"id42", ref:"GOE", price: 42}]}])
 * //returns {GOE: {tenders: {id42: 42}}}
 */
function applyTendersToClassificationDict(
  dict: IClassification,
  tenders?: IMappedTender[]
) {
  tenders?.forEach((tender) => {
    tender?.mapped_price_averages?.forEach((price_average) => {
      setNested(
        dict,
        // compute the nested path in the dict, and set the price on the key corresponding to the tenderId
        price_average.ref
          .split(".")
          .map((ref) => `children.${ref}`)
          .join(".") + `.tenders.${tender.id}`,
        // compute average price
        price_average.price / price_average.quantity,
        true
      );
    });
  });
  return dict;
}

export const IGNORE_ENTIRE_TENDER = "EVERYTHING";

export function isEntireTenderIgnored(
  tender: IMappedTender,
  priceAveragePanel?: IPriceAveragePanel
) {
  return (
    priceAveragePanel?.ignored_classifications[tender.id]?.includes(
      IGNORE_ENTIRE_TENDER
    ) ?? false
  );
}
