import { ReactNode } from "react";

export enum MODAL_SIZE_ENUM {
  LG = "lg",
  XL = "xl",
}

export interface IStep {
  /**
   * The component to render for the Step
   */
  component: ReactNode;
  /**
   * function to check if the step is completed
   * @param index
   * @returns boolean
   */
  isCompleted: (index: number) => boolean;
  /**
   * callback to call before going to the next step
   * can be used by a step to do API calls and decide to continue to the next step or not
   * @returns Promise<boolean>
   */
  beforeNextStep?: () => Promise<boolean>;
  /**
   * function to test if the step is completed
   * @returns boolean
   */
  isOptional?: () => boolean;
  /**
   * size of the modal for this step
   */
  modalSize: MODAL_SIZE_ENUM;
  /**
   * function to determine if there should be space between the buttons in the footer
   * @returns boolean
   */
  footerHasSpace?: () => boolean;
  /**
   * if the previous button should be hidden
   */
  hidePrevious?: boolean;
}

export interface IMappingStep {
  optionalStep?: OPTIONNAL_STEP_ENUM;
  instructions: string;
  columnMode: boolean;
  reverseMode?: boolean;
  allowMultipleSelections?: boolean;
}

export type TLotsMappings = (number[] | undefined)[];

export enum OPTIONNAL_STEP_ENUM {
  ALWAYS = "ALWAYS",
  FRAME_ONLY = "FRAME_ONLY",
}

// Check if a mapping step is completed, i.e. it's optional or at least one selection has been made
export function isStepCompleted(
  table: TLotsMappings,
  steps: IMappingStep[],
  index: number,
  isOnlyFrame?: boolean
) {
  return Boolean(
    (table[index] ?? []).length > 0 ||
      steps[index]?.optionalStep === OPTIONNAL_STEP_ENUM.ALWAYS ||
      (isOnlyFrame &&
        steps[index]?.optionalStep === OPTIONNAL_STEP_ENUM.FRAME_ONLY)
  );
}

export const mappingSteps: IMappingStep[] = [
  {
    instructions: "select header line",
    columnMode: false,
  },
  {
    instructions: "select last line",
    columnMode: false,
    reverseMode: true,
    optionalStep: OPTIONNAL_STEP_ENUM.ALWAYS,
  },
  {
    instructions: "select nomenclature column",
    columnMode: true,
    optionalStep: OPTIONNAL_STEP_ENUM.ALWAYS,
  },
  {
    instructions: "select designation column",
    columnMode: true,
    allowMultipleSelections: true,
  },
  {
    instructions: "select unit column",
    columnMode: true,
  },
  {
    instructions: "select quantities column",
    columnMode: true,
    optionalStep: OPTIONNAL_STEP_ENUM.FRAME_ONLY,
  },
  {
    instructions: "select price column",
    columnMode: true,
    optionalStep: OPTIONNAL_STEP_ENUM.FRAME_ONLY,
  },
  {
    instructions: "select amountHT column",
    columnMode: true,
    optionalStep: OPTIONNAL_STEP_ENUM.ALWAYS,
  },
];

export function getStepIndex(
  selectedIndex: number,
  sheetMappingStartIndex: number
) {
  return Math.max(selectedIndex - sheetMappingStartIndex, 0);
}
