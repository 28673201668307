import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { useForm } from "react-hook-form";

import { createCompany } from "../api/fetchCompanies";
import { uploadFile } from "../api/fetchDocuments";
import {
  createTender,
  createTenderData,
  deleteTender,
  updateTender,
} from "../api/fetchTenders";
import { mapLogs } from "../compare/PriceNodeEditModal/PriceNodeEditTable";
import {
  ExcelImportStepMapper,
  getLastValidStepIndex,
} from "../lots/ExcelImportStepMapper";
import { LotsModalContent } from "../lots/LotsModalContent";
import { SelectTab } from "../lots/SelectTab";
import { IBackErrors } from "../shared/errors";
import { useTranslation } from "../shared/i18n";
import { CircleQuestionIcon } from "../shared/icons";
import { ACCEPT_EXCEL_FILE_FORMAT, ImportFilesArea } from "../shared/inputs";
import { ConfirmationModal } from "../shared/modals";
import { ICompanyDTO } from "../shared/types/company.types";
import { ILogs } from "../shared/types/log.types";
import { TCell } from "../shared/types/lot.types";
import {
  IStep,
  MODAL_SIZE_ENUM,
  TLotsMappings,
  getStepIndex,
  isStepCompleted,
  mappingSteps,
} from "../shared/types/lots.types";
import { mapStepMappingsToSingleMapping } from "../shared/types/mapping.types";
import { ITender } from "../shared/types/tender.types";
import { QUERY_KEYS_ENUM, useWorksQuery } from "../shared/useSharedQueries";

import {
  CompanySelectionCreation,
  EVENT_KEY_ENUM,
} from "./CompanySelectionCreation";
import {
  TVersionFormInputs,
  TenderAmountForm,
  schema,
} from "./TenderAmountForm";
import { TTenderFormInputs, isFormValid } from "./TenderForm";

export interface ITenderCreationModalParameters {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  defaultFiles?: File[];
  stepsToSkip?: number[];
  lot: string;
  operationId: string;
  company?: ICompanyDTO;
}

export function TenderCreationModal({
  show,
  handleClose,
  defaultFiles,
  stepsToSkip,
  lot,
  operationId,
  company: defaultCompany,
}: ITenderCreationModalParameters) {
  const { t } = useTranslation("TenderCreationModal");
  const [files, setFiles] = useState<File[] | undefined>(defaultFiles);
  const [tab, setTab] = useState<string>();
  const [sheets, setSheets] = useState<{
    sheets: { [key: string]: () => TCell[][] };
    rawSheets: { [key: string]: () => any };
  }>({ sheets: {}, rawSheets: {} });
  const [mappings, setMappings] = useState<TLotsMappings>([[]]);
  const [updatedPriceTenderData, setUpdatedPriceTenderData] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tenderForm, setTenderForm] = useState<TTenderFormInputs>();
  const [tender, setTender] = useState<ITender>();
  const [tenderAmount, setTenderAmount] = useState<TVersionFormInputs>();
  const [company, setCompany] = useState<ICompanyDTO | undefined>(
    defaultCompany
  );
  const [activeKey, setActiveKey] = useState<string | null>(
    EVENT_KEY_ENUM.EXISTING
  );
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [percentage, setPercentage] = useState<number | undefined>(undefined);

  const worksQuery = useWorksQuery(operationId);
  const [work] = worksQuery.data ?? [];

  const [mappingLogs, setMappingLogs] = useState<ILogs>();

  const {
    register: registerEstimation,
    handleSubmit: handleSubmitEstimation,
    getValues: getEstimationValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, dirtyFields }, // dirtyFields required to update
  } = useForm<TVersionFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const tenderFileUpload = useMutation({
    mutationFn: (tender: ITender) =>
      tender !== undefined && files?.length
        ? uploadFile(tender, files![0], setPercentage)
        : Promise.reject("no tender or file"),
  });

  const tenderCreation = useMutation({
    mutationFn: createTender,
  });

  const tenderEdition = useMutation({
    mutationFn: updateTender,
  });

  const tenderDeletion = useMutation({
    mutationFn: deleteTender,
  });

  const tenderDataCreation = useMutation({
    mutationFn: createTenderData,
  });

  const companyCreation = useMutation({
    mutationFn: createCompany,
  });

  const submitCompany = useCallback(async () => {
    const company = await companyCreation.mutateAsync({
      // location_street: tenderForm,
      location_postcode: tenderForm!.location_postcode!,
      location_city: tenderForm!.location_city!,
      // location_district: ,
      // location_country: ,
      name: tenderForm!.name!,
    });
    setCompany(company);
  }, [tenderForm, companyCreation]);

  const [submitError, setSubmitError] = useState<any>();

  const submitStep = useCallback(async () => {
    try {
      const mutation = tender ? tenderEdition : tenderCreation;

      const mapping = mapStepMappingsToSingleMapping(mappings, tab!, work?.id);
      // abort if the start line isn't valid
      if (!(mapping.ranges[0].start >= 0)) {
        return;
      }
      const newTender = await mutation.mutateAsync({
        id: tender?.id,
        allotment: lot,
        company: company!.id,
        estimation_amount: tenderAmount?.estimation_amount,
        upload_date: new Date(),
        mapping,
        file_name: files![0].name,
      });
      setTender(newTender);
      setMappingLogs(mapLogs(newTender)[1]);
      // set raw_data only once on creation
      if (!updatedPriceTenderData) {
        await tenderDataCreation.mutateAsync({
          id: newTender.id,
          raw_data: sheets.rawSheets[tab!](),
        });
        setUpdatedPriceTenderData(true);
      }

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lot],
      });

      setSubmitError(undefined);
    } catch (error) {
      console.error(error);
      setSubmitError(error);
    }
  }, [
    company,
    files,
    lot,
    mappings,
    queryClient,
    sheets.rawSheets,
    tab,
    tender,
    tenderAmount?.estimation_amount,
    tenderCreation,
    tenderDataCreation,
    tenderEdition,
    updatedPriceTenderData,
    work?.id,
  ]);

  const submit = useCallback(async () => {
    try {
      if (tender && files?.length) {
        const newTender = await tenderEdition.mutateAsync({
          id: tender.id,
          mapping: mapStepMappingsToSingleMapping(
            mappings,
            tab!,
            work?.id,
            true
          ),
        });
        setTender(newTender);
        setMappingLogs(mapLogs(newTender)[1]);
        console.info(
          `Import logs for tender ${tender.id}: `,
          newTender.import_logs
        );

        await tenderFileUpload.mutate(tender);
      }
      handleClose();
    } catch (error) {
      console.warn(error);
      setSubmitError(error);
    }
  }, [
    tender,
    files?.length,
    handleClose,
    tenderEdition,
    mappings,
    tab,
    work?.id,
    tenderFileUpload,
  ]);

  const handleCancel = useCallback(async () => {
    if (tender?.id) {
      await tenderDeletion.mutateAsync(tender.id);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lot],
      });
    }
    handleClose();
  }, [handleClose, lot, queryClient, tender, tenderDeletion]);

  const isSubmitting =
    companyCreation.isLoading ||
    tenderCreation.isLoading ||
    tenderEdition.isLoading ||
    tenderDataCreation.isLoading ||
    tenderFileUpload.isLoading;

  const sheetMappingStartIndex = 3;

  const currentMappingStep =
    mappingSteps[getStepIndex(selectedIndex, sheetMappingStartIndex)];

  const attemptSubmitAmountAndCompany = useCallback(async () => {
    if (!schema.isValidSync(getEstimationValues())) {
      // the estimation form is invalid
      return false;
    } else {
      // set the tender amount
      setTenderAmount(getEstimationValues());
      if (defaultCompany) {
        // this is an edit
        return true;
      } else {
        if (activeKey === EVENT_KEY_ENUM.EXISTING) {
          if (selectedCompany) {
            // an existing company has been selected, update the id
            setCompany({ id: selectedCompany } as ICompanyDTO);
            return true;
          }
        } else if (isFormValid(tenderForm)) {
          // create a new company
          await submitCompany();
          return true;
        }
        return false;
      }
    }
  }, [
    activeKey,
    getEstimationValues,
    selectedCompany,
    submitCompany,
    tenderForm,
    defaultCompany,
  ]);

  const attemptSubmitAmountAndCompanyNext = useCallback(async () => {
    if (await attemptSubmitAmountAndCompany()) {
      setSelectedIndex((selectedIndex) => selectedIndex + 1);
    }
  }, [attemptSubmitAmountAndCompany]);

  const steps: IStep[] = useMemo(() => {
    const steps = [
      {
        component: (
          <div className="mx-5">
            <TenderAmountForm
              handleSubmit={handleSubmitEstimation(
                attemptSubmitAmountAndCompanyNext
              )}
              errors={errors}
              register={registerEstimation}
            />
            {!defaultCompany && (
              <CompanySelectionCreation
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                setSelectedCompany={setSelectedCompany}
                selectedCompany={selectedCompany!}
                setTenderForm={setTenderForm}
                tenderForm={tenderForm}
                submit={attemptSubmitAmountAndCompanyNext}
                errors={companyCreation.error as IBackErrors<TTenderFormInputs>}
              />
            )}
          </div>
        ),
        isCompleted: () =>
          schema.isValidSync(getEstimationValues()) &&
          (Boolean(defaultCompany) ||
            (activeKey === EVENT_KEY_ENUM.CREATE && isFormValid(tenderForm)) ||
            (activeKey === EVENT_KEY_ENUM.EXISTING &&
              Boolean(selectedCompany))),
        modalSize: MODAL_SIZE_ENUM.LG,

        beforeNextStep: attemptSubmitAmountAndCompany,
      },
      {
        component: (
          <>
            <Alert
              variant="info"
              className="text-center d-flex justify-content-center align-items-center px-5"
            >
              <CircleQuestionIcon />
              <span className="mx-5 text-dark">{t("add DGPF")}</span>
            </Alert>
            <ImportFilesArea
              disabled={percentage !== undefined && percentage < 100}
              percentage={percentage! < 100 ? percentage : undefined}
              initialData={files}
              onData={setFiles}
              buttonText={t("import document")}
              areaText={t("or drag document here")}
              enableFileEdit
              accept={ACCEPT_EXCEL_FILE_FORMAT}
            />
          </>
        ),
        isCompleted: () => Boolean(files && files.length > 0),
        modalSize: MODAL_SIZE_ENUM.LG,
        hidePrevious: true,
      },
      {
        component: (
          <SelectTab
            onData={setTab}
            initialData={tab}
            tabs={Object.keys(sheets.sheets)}
          />
        ),
        isCompleted: () => !!tab,
        modalSize: MODAL_SIZE_ENUM.LG,
      },
      {
        component: (
          <ExcelImportStepMapper
            selectedStepIndex={getStepIndex(
              selectedIndex,
              sheetMappingStartIndex
            )}
            setSelectedStepIndex={(index) =>
              setSelectedIndex(index + sheetMappingStartIndex)
            }
            setMappings={(mappings) => {
              setMappings(mappings);
              submitStep();
            }}
            sheet={tab ? sheets.sheets[tab]?.() : []}
            currentStep={currentMappingStep}
            mappings={mappings}
            sheetMappingStartIndex={sheetMappingStartIndex}
            lastValidStepIndex={getLastValidStepIndex(
              selectedIndex,
              mappings,
              getStepIndex,
              sheetMappingStartIndex,
              isStepCompleted,
              mappingSteps
            )}
            submitError={submitError}
            logs={mappingLogs}
          />
        ),
        isCompleted: (selectedIndex: number) =>
          isStepCompleted(
            mappings,
            mappingSteps,
            getStepIndex(selectedIndex, sheetMappingStartIndex)
          ),
        modalSize: MODAL_SIZE_ENUM.XL,
      },
    ];

    // filter out the steps to skip
    if (stepsToSkip) {
      // remove elements in place by index from end to start
      stepsToSkip.sort((a, b) => b - a);
      stepsToSkip.forEach((step) => steps.splice(step, 1));
    }
    return steps;
  }, [
    handleSubmitEstimation,
    attemptSubmitAmountAndCompanyNext,
    errors,
    registerEstimation,
    defaultCompany,
    activeKey,
    selectedCompany,
    tenderForm,
    companyCreation.error,
    attemptSubmitAmountAndCompany,
    t,
    percentage,
    files,
    tab,
    sheets.sheets,
    selectedIndex,
    currentMappingStep,
    mappings,
    submitError,
    mappingLogs,
    stepsToSkip,
    getEstimationValues,
    submitStep,
  ]);
  return (
    <>
      <ConfirmationModal
        title={t("Close and lose steps?")}
        show={showConfirmationModal}
        handleClose={(confirm) =>
          confirm ? handleCancel() : setShowConfirmationModal(false)
        }
      />
      <LotsModalContent
        show={show}
        handleClose={() =>
          selectedIndex > 0 ? setShowConfirmationModal(true) : handleClose()
        }
        steps={steps}
        selectedIndex={selectedIndex}
        sheetMappingStartIndex={sheetMappingStartIndex}
        files={files}
        setSheets={setSheets}
        setTab={setTab}
        setSelectedIndex={setSelectedIndex}
        mappingSteps={mappingSteps}
        title={t(defaultCompany ? "title add" : "title create")}
        onSubmit={submit}
        isLoading={isSubmitting || worksQuery.isLoading}
      />
    </>
  );
}
