import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { updatePriceEstimate } from "../../api/fetchEstimates";
import { updateTender } from "../../api/fetchTenders";
import { IDocumentData } from "../../shared/types/document.types";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import { IMapping, MAPPING_STEP_ENUM } from "../../shared/types/mapping.types";
import {
  IRowInstruction,
  ROW_INSTRUCTIONS_ENUM,
} from "../../shared/types/rowInstruction.types";
import { isTender } from "../../shared/types/tender.types";
import { QUERY_KEYS_ENUM } from "../../shared/useSharedQueries";

interface IAddInstruction {
  document: IDocumentData;
  row_instructions?: IRowInstruction[];
}

export function useCompareTableCells(
  base: IPriceEstimate | undefined,
  compareDocuments: IDocumentData[]
) {
  const queryClient = useQueryClient();

  const invalidateQueries = (document: IDocumentData) => {
    if (isTender(document)) {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.TENDER, document.id]);
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.TENDER_DATA, document.id]);
      queryClient.invalidateQueries([
        QUERY_KEYS_ENUM.TENDERS,
        document.allotment,
      ]);
    } else {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.ESTIMATE, document.id]);
      queryClient.invalidateQueries([
        QUERY_KEYS_ENUM.ESTIMATE_DATA,
        document.id,
      ]);
    }
  };

  const updateDocumentRowInstructions = useCallback(
    ({ document, row_instructions }: IAddInstruction) => {
      const update = isTender(document) ? updateTender : updatePriceEstimate;
      // TODO? optimistic update
      return update({
        id: document.id,
        mapping: {
          ...document.mapping,
          step: MAPPING_STEP_ENUM.COMPUTED,
          row_instructions,
        } as IMapping,
      });
    },
    []
  );

  const addInstruction = useMutation({
    mutationFn: updateDocumentRowInstructions,
    onSuccess: invalidateQueries,
  });

  const updateCell = useCallback(
    (
      documentId: string,
      work_id: string,
      row_number: number,
      value: number | null,
      type:
        | ROW_INSTRUCTIONS_ENUM.SET_QUANTITY
        | ROW_INSTRUCTIONS_ENUM.SET_UNIT_PRICE
    ) => {
      const document =
        base?.id === documentId
          ? base
          : compareDocuments.find((doc) => doc.id === documentId);
      if (!document) {
        console.error("no matching document found");
        return;
      }
      const instruction: IRowInstruction = {
        type,
        subject_rows: [row_number],
        value: value,
        work_id,
      };
      addInstruction.mutate({
        document,
        row_instructions: [
          ...(document.mapping?.row_instructions ?? []),
          instruction,
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addInstruction, base, ...compareDocuments, compareDocuments]
  );

  const updateQuantity = useCallback(
    (
      documentId: string,
      work_id: string,
      row_number: number,
      value: number | null
    ) =>
      updateCell(
        documentId,
        work_id,
        row_number,
        value,
        ROW_INSTRUCTIONS_ENUM.SET_QUANTITY
      ),
    [updateCell]
  );
  const updateUnitPrice = useCallback(
    (
      documentId: string,
      work_id: string,
      row_number: number,
      value: number | null
    ) =>
      updateCell(
        documentId,
        work_id,
        row_number,
        value,
        ROW_INSTRUCTIONS_ENUM.SET_UNIT_PRICE
      ),
    [updateCell]
  );

  return {
    updateQuantity,
    updateUnitPrice,
  };
}
