import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { NumberDifferenceAbsoluteRelative } from "../compare/CompareSettingsModal/NumberDifferenceAbsoluteRelative";
import { DefaultModal } from "../shared/DefaultModal";
import { useEnumTranslation, useTranslation, yupLocale } from "../shared/i18n";
import { Input, Select, Switch, getInputProps } from "../shared/inputs";
import { yupNumber } from "../shared/inputs/input";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../shared/visualization/Difference";

import {
  IPriceAverageSettings,
  PriceAverageMode,
} from "./PriceAverageTable.types";

yup.setLocale(yupLocale);
const schema = yup.object({
  mode: yup.string().required().oneOf(Object.values(PriceAverageMode)),
  number_difference: yup
    .object({
      active: yup.boolean().required(),
      mode: yup
        .string()
        .required()
        .oneOf(Object.values(DIFFERENCE_DISPLAY_MODE_ENUM)),
      positive_threshold: yupNumber().min(0).integer().required(),
      negative_threshold: yupNumber().min(0).integer().required(),
    })
    .required(),
});

export type TPriceAverageSettingsFormInputs = yup.InferType<typeof schema>;

export const defaultPriceAverageSettings = {
  mode: PriceAverageMode.PRICE_AVERAGE,
  number_difference: {
    active: true,
    mode: DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT,
    positive_threshold: 15,
    negative_threshold: 15,
  },
};

export function PriceAverageSettingsModal({
  show,
  handleClose,
  settings = defaultPriceAverageSettings,
  onSubmit,
}: {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  settings?: IPriceAverageSettings;
  onSubmit(formInputs: TPriceAverageSettingsFormInputs): void;
}) {
  const { t } = useTranslation("PriceAverageSettingsModal");
  const { tEnum } = useEnumTranslation();

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TPriceAverageSettingsFormInputs>({
    resolver: yupResolver(schema),
    values: settings,
    mode: "onSubmit",
  });
  const isNumberDifferenceActive = watch("number_difference.active");

  const modes = useMemo(
    () =>
      Object.values(PriceAverageMode).map((mode) => ({
        value: mode,
        label: tEnum(mode),
      })),
    [tEnum]
  );

  return (
    <DefaultModal
      show={show}
      title={t("title")}
      handleClose={handleClose}
      staticBackdrop
    >
      <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3 pb-3 border-bottom">
          <Col>
            <h6>{t("mode")}</h6>
            <Row className="mb-3">
              <Col>
                <Controller
                  name="mode"
                  control={control}
                  render={({ field }) => (
                    <Select
                      searchable
                      searchValueAsOption
                      options={modes}
                      {...field}
                      placeholder={t("select mode")}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3 pb-3 border-bottom">
          <Col>
            <h6>{t("number_difference")}</h6>
            <Row className="mb-3">
              <Col>
                <Switch
                  control={control}
                  name="number_difference.active"
                  label={t("activate")}
                />
              </Col>
            </Row>
            <fieldset disabled={!isNumberDifferenceActive}>
              <Controller
                name="number_difference.mode"
                control={control}
                render={(controllerProps) => (
                  <NumberDifferenceAbsoluteRelative
                    // cast to any since field is a subset and TS complains that '"mode"' is not assignable to '"number_difference.mode"'
                    {...(controllerProps as any)}
                  />
                )}
              />

              <Row className="mb-3">
                <Input
                  as={Col}
                  xs="auto"
                  {...getInputProps(
                    "number_difference.negative_threshold",
                    register,
                    schema,
                    errors
                  )}
                  label={t("set negative threshold")}
                  tooltip={t("negative threshold tooltip")}
                  placeholder={String(
                    defaultPriceAverageSettings.number_difference
                      .negative_threshold
                  )}
                  inputGroupBefore={<InputGroup.Text>-</InputGroup.Text>}
                  inputGroupAfter={<InputGroup.Text>%</InputGroup.Text>}
                />
                <Input
                  as={Col}
                  xs="auto"
                  {...getInputProps(
                    "number_difference.positive_threshold",
                    register,
                    schema,
                    errors
                  )}
                  label={t("set positive threshold")}
                  tooltip={t("positive threshold tooltip")}
                  placeholder={String(
                    defaultPriceAverageSettings.number_difference
                      .positive_threshold
                  )}
                  inputGroupBefore={<InputGroup.Text>+</InputGroup.Text>}
                  inputGroupAfter={<InputGroup.Text>%</InputGroup.Text>}
                />
              </Row>
            </fieldset>
          </Col>
        </Row>
        <Row className="my-4 justify-content-center">
          <Col xs="auto">
            <Button size="lg" type="submit">
              {t("validate")}
            </Button>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
}
