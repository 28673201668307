import { parseISO } from "date-fns";

import { toISODate } from "../../api/APIUtils";

export enum ROW_INSTRUCTIONS_ENUM {
  DELETE = "DELETE",
  ASSOCIATE = "ASSOCIATE",
  DISSOCIATE = "DISSOCIATE",
  UNPARENT = "UNPARENT",
  PARENT_INTRINSIC = "PARENT_INTRINSIC",
  PARENT_EXTERNAL = "PARENT_EXTERNAL",
  MOVE_INTRINSIC_BEFORE = "MOVE_INTRINSIC_BEFORE",
  MOVE_INTRINSIC_AFTER = "MOVE_INTRINSIC_AFTER",
  MOVE_EXTERNAL_BEFORE = "MOVE_EXTERNAL_BEFORE",
  MOVE_EXTERNAL_AFTER = "MOVE_EXTERNAL_AFTER",
  SET_QUANTITY = "SET_QUANTITY",
  SET_UNIT_PRICE = "SET_UNIT_PRICE",
}

export enum ROW_TYPE_INSTRUCTIONS_ENUM {
  SET_TYPE = "SET_TYPE",
  SET_CLASSIFICATION_QUANTITY = "SET_CLASSIFICATION_QUANTITY",
}

export interface ISetTypeDetail {
  type?: string | null;
  quantity_std?: IQuantityStd[];
  price_std?: IPriceStd[];
}

export interface IQuantityStd {
  work_id: string;
  quantity_std: number;
  row_numbers?: number[];
  formula?: string;
}

export interface IPriceStd {
  work_id: string;
  price_std: number;
  row_numbers?: number[];
  formula?: string;
}

export interface IRowInstructionDTO {
  /** action type */
  type: ROW_INSTRUCTIONS_ENUM;
  /** list of row_numbers from selected PriceNodes on price_breakdown */
  subject_rows: number[];
  /** row_number of target PriceNode on price_estimate (PAIR) or price_breakdown (MOVE) */
  target_row?: number;
  /** creation date */
  creation_date?: string;
  /** work targeted by the instruction */
  work_id?: string;
  /** value used by the instruction */
  value?: any;
}

export interface IRowInstruction
  extends Omit<IRowInstructionDTO, "creation_date"> {
  creation_date?: Date;
}

export interface IRowTypeInstructionDTO {
  /** action type */
  type: ROW_TYPE_INSTRUCTIONS_ENUM;
  /** list of row_numbers from selected PriceNodes on price_breakdown */
  subject_rows: number[];
  /** creation date */
  creation_date?: string;
  /** additional information per type*/
  detail?: ISetTypeDetail;
}

export interface IRowTypeInstruction {
  /** action type */
  type: ROW_TYPE_INSTRUCTIONS_ENUM;
  /** list of row_numbers from selected PriceNodes on price_breakdown */
  subject_rows: number[];
  /** creation date */
  creation_date?: Date;
  /** additional information per type*/
  detail?: ISetTypeDetail;
}

export function rowInstructionDTOToRowInstructionMapper(
  rowInstruction: IRowInstructionDTO
): IRowInstruction {
  return {
    ...rowInstruction,
    creation_date: rowInstruction.creation_date
      ? parseISO(rowInstruction.creation_date)
      : undefined,
  };
}

export function rowInstructionToRowInstructionDTOMapper(
  rowInstruction: IRowInstruction
): IRowInstructionDTO {
  return {
    ...rowInstruction,
    creation_date: rowInstruction.creation_date
      ? toISODate(rowInstruction.creation_date)
      : undefined,
  };
}

export function rowTypeInstructionDTOToRowTypeInstructionMapper(
  row_type_instruction: IRowTypeInstructionDTO
): IRowTypeInstruction {
  return {
    ...row_type_instruction,
    creation_date: row_type_instruction.creation_date
      ? parseISO(row_type_instruction.creation_date)
      : undefined,
  };
}

export function rowTypeInstructionToRowTypeInstructionDTOMapper(
  rowTypeInstruction: IRowTypeInstruction
): IRowTypeInstructionDTO {
  return {
    ...rowTypeInstruction,
    creation_date: rowTypeInstruction.creation_date
      ? toISODate(rowTypeInstruction.creation_date)
      : undefined,
  };
}
