import classNames from "classnames";
import React from "react";

import { LogsCell } from "../compare/CompareTable/CompareTableCells";
import { useTranslation } from "../shared/i18n";
import {
  ILogs,
  IMappingLog,
  LOG_LEVEL_ENUM,
  LOG_TYPE_ENUM,
} from "../shared/types/log.types";

import styles from "./ExcelImportSteps.module.scss";

export interface ILotsStepsProps {
  enabledIndex: number;
  selectedIndex: number;
  selectIndex: (index: number) => void;
  logs?: ILogs;
}

export enum STEP_ENUM {
  "HEADER" = "HEADER",
  "ENDLINE" = "ENDLINE",
  "NOMENCLATURE" = "NOMENCLATURE",
  "DESIGNATION" = "DESIGNATION",
  "UNIT" = "UNIT",
  "QUANTITY" = "QUANTITY",
  "UNIT_PRICE" = "UNIT_PRICE",
  "TOTAL_PRICE" = "TOTAL_PRICE",
}
const steps = Object.values(STEP_ENUM);
export function ExcelImportSteps({
  enabledIndex,
  selectedIndex,
  selectIndex,
  logs,
}: ILotsStepsProps) {
  const { t } = useTranslation("ColumnName");
  return (
    <div className="d-flex justify-content-between overflow-auto">
      {steps.map((step, index) => [
        <hr
          key={`${index}-hr`}
          className={classNames(styles.hr, {
            [styles.disabled]: index > enabledIndex,
          })}
        />,
        <div key={index} className={classNames(styles.item)}>
          <input
            type="radio"
            name="lots-steps"
            id={`lots-steps-${index}`}
            className="d-none"
            disabled={index > enabledIndex}
            checked={index === selectedIndex}
            onChange={() => selectIndex(index)}
          />
          <label
            htmlFor={`lots-steps-${index}`}
            className="d-flex flex-column justify-content-center align-items-center text-center"
          >
            <span
              className={classNames(
                styles.roundedCircle,
                "text-light fw-bold rounded-circle d-flex align-items-center justify-content-center position-relative"
              )}
            >
              <div className="position-absolute top-0 start-100 mt-2">
                <LogsCell
                  value={filterLogColumn(step, logs)}
                  dict={{}}
                  otherDict={{}}
                />
              </div>
              {index + 1}
            </span>
            <span>{t(step)}</span>
          </label>
        </div>,
      ])}
    </div>
  );
}

/**
 * Filters logs relevant to a single column
 * @param column column name
 * @param logs ILogs
 * @returns filtered ILogs
 */
function filterLogColumn(column: STEP_ENUM, logs?: ILogs) {
  return Object.entries(logs ?? {})
    .map(([key, value]: [string, IMappingLog[]]) => [
      key,
      value.filter(
        (log) =>
          log.parameters?.[1].endsWith(column) &&
          filterNomenclatureColumnEmpty(column, log)
      ),
    ])
    .reduce((previous, [key, value]) => {
      previous[key as LOG_LEVEL_ENUM] = value as IMappingLog[];
      return previous;
    }, {} as ILogs);
}

/**
 * avoid displaying column empty warning for nomenclature since it's common
 * @param column
 * @param log
 * @returns
 */
function filterNomenclatureColumnEmpty(column: STEP_ENUM, log: IMappingLog) {
  return (
    column !== STEP_ENUM.NOMENCLATURE ||
    log.type !== LOG_TYPE_ENUM.COLUMN_TYPE ||
    log.parameters?.[2]! < log.parameters?.[3]!
  );
}
