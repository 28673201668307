import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { CellContext, Row as ReactRow, Table } from "@tanstack/react-table";
import classNames from "classnames";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { NavLink, useParams, useSearchParams } from "react-router-dom";

import { i18n } from "../../src/host/i18n";
import { useOrganizationNameRights } from "../account/UserOrganizationGuard";
import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../api/APIUtils";
import { updateEstimateRowTypeMapping } from "../api/fetchEstimates";
import { fetchLot } from "../api/fetchLots";
import { createRowType } from "../api/fetchRowTypes";
import {
  fetchTenderRowTypeMapping,
  updateTenderMappedPriceAverages,
  updateTenderRowTypeMapping,
} from "../api/fetchTenders";
import { useCompareDifferenceSettings } from "../compare/CompareDifference";
import { CompareSettingsModal } from "../compare/CompareSettingsModal";
import styles from "../compare/CompareTable/CompareTable.module.scss";
import { ICompareRow } from "../compare/CompareTable/CompareTable.types";
import { useCompareTable } from "../compare/CompareTable/useCompareTable";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "../compare/Table.types";
import { useCompareColumnDisplaySettings } from "../compare/useCompareColumnDisplaySettings";
import { useComparedEstimateAndTenders } from "../compare/useComparedEstimateAndTenders";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useModal } from "../shared/DefaultModal";
import colors from "../shared/_exports.module.scss";
import { IBackErrors } from "../shared/errors";
import { Header, INav } from "../shared/header";
import { formatCurrency, useTranslation } from "../shared/i18n";
import {
  AddCircledIcon,
  BackArrowIcon,
  CogIcon,
  SaveIcon,
} from "../shared/icons";
import { AllotmentSelect } from "../shared/inputs/AllotmentSelect";
import { IOption, TValue } from "../shared/inputs/select";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { TPriceEstimateWithTotal } from "../shared/types/estimate.types";
import { IAttribution, IPriceNode } from "../shared/types/priceNode.types";
import {
  IRowTypeInstruction,
  ROW_TYPE_INSTRUCTIONS_ENUM,
} from "../shared/types/rowInstruction.types";
import { IRowType, ROWTYPE_USAGE_ENUM } from "../shared/types/rowType.types";
import { ITender, isTender } from "../shared/types/tender.types";
import { useHasChangedRef } from "../shared/useHasChanged";
import {
  QUERY_KEYS_ENUM,
  useDocumentRowTypeMappingDataQuery,
  useRowTypesQuery,
} from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";
import { accessNested, ifTest, setNested } from "../shared/utils";

import { ClassificationTable } from "./ClassificationTable";
import { MappingTable, MappingTableLegend } from "./MappingTable";
import mappingToolPageStyles from "./MappingToolPage.module.scss";
import { applyRowTypeInstructions } from "./MappingUtils";
import { RowTypeCell } from "./RowTypeCell";
import { RowTypeModal } from "./RowTypeModal";
import { StandardQuantityCellClassification } from "./StandardQuantityCell";
import {
  IInitialInstructions,
  IInstructions,
  IMappingPriceNode,
  IRowTypeRow,
  IStdUnitMeta,
} from "./mapping.types";

export const ROW_TYPE_COLUMN_ID = "row_type";
export const STANDARD_QUANTITY_COLUMN_ID = "standard_quantity";
export const STANDARD_PRICE_COLUMN_ID = "standard_price";
export const UNIT_PRICE_COLUMN_ID = "unit_price";

export const ALLOTMENT_TYPE_SEARCH_PARAM = "allotment_type";

const defaultCompareColumnDisplaySettings = [
  TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
  TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
];

export function MappingToolPage() {
  const { t } = useTranslation("MappingToolPage");

  const [searchParams, setSearchParams] = useSearchParams();

  const [allotment_type, setAllotment_type] = useState<string | undefined>(
    searchParams.get(ALLOTMENT_TYPE_SEARCH_PARAM) ?? undefined
  );
  const lotScopeNavList: INav[] = useMemo(
    () => [
      {
        Icon: BackArrowIcon,
        path: `/${PATH_NAMES_ENUM.MAPPING}/${allotment_type}`,
        back: true,
        overlay: <Tooltip>{t("returnMapping")}</Tooltip>,
        id: "navReturnMapping",
      },
    ],
    [t, allotment_type]
  );

  const { operationId, lotId } = useParams();
  const [isDirty, setIsDirty] = useState(false);
  const tableRef = useRef<Table<IRowTypeRow>>();

  useTrackPageView(
    "MappingToolPage",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
    ],
    [operationId, lotId]
  );
  const { tenders, estimate, isLoading, isInitialLoading, setTenders } =
    useComparedEstimateAndTenders(lotId);

  const initialInstructions = useRef<IInitialInstructions>({
    estimate: [],
    tenders: {},
  });
  const [instructions, setInstructions] = useState<IInstructions>({
    estimate: {},
    tenders: {},
  });
  const setInstructionsDirty = useCallback(
    (instructions: SetStateAction<IInstructions>) => {
      setInstructions(instructions);
      setIsDirty(true);
    },
    []
  );

  const estimate_row_type_mapping = useDocumentRowTypeMappingDataQuery(
    estimate?.id,
    false
  );

  if (
    useHasChangedRef(estimate_row_type_mapping.data) &&
    estimate_row_type_mapping.data
  ) {
    initialInstructions.current = {
      ...initialInstructions.current,
      estimate: estimate_row_type_mapping.data.row_type_instructions ?? [],
    };
  }

  const tenders_row_type_mapping = useQueries({
    queries:
      tenders?.map(({ id }) => ({
        queryKey: [QUERY_KEYS_ENUM.TENDER_ROW_TYPE_MAPPING, id],
        queryFn: () => fetchTenderRowTypeMapping(id!),
        enabled: Boolean(id),
      })) ?? [],
  });

  const tendersLoading = tenders_row_type_mapping.some(
    (query) => query.isLoading
  );

  if (
    useHasChangedRef(
      ...tenders_row_type_mapping.map((tender) => tender.data)
    ) &&
    !tendersLoading
  ) {
    const dict: { [key: string]: IRowTypeInstruction[] } = {};
    tenders_row_type_mapping.forEach((row, index) => {
      dict[tenders[index].id] = row.data?.row_type_instructions ?? [];
    });
    initialInstructions.current = {
      ...initialInstructions.current,
      tenders: dict,
    };
  }

  const isDataLoading =
    isLoading || estimate_row_type_mapping.isLoading || tendersLoading;
  const [compareDifferenceSettings, setCompareDifferenceSettings] =
    useCompareDifferenceSettings(isInitialLoading, estimate);

  const [compareColumnDisplaySettings, setCompareColumnDisplaySettings] =
    useCompareColumnDisplaySettings(defaultCompareColumnDisplaySettings);

  const queryClient = useQueryClient();

  const {
    show: showSettingsModal,
    handleClose: handleCloseSettingsModal,
    handleShow: handleShowSettingsModal,
  } = useModal("CompareSettingsModal");

  const {
    show: showRowTypeModal,
    handleClose: handleCloseRowTypeModal,
    handleShow: handleShowRowTypeModal,
  } = useModal("CompareRowTypeModal");

  // useRef to avoid triggering rerenders since doesn't have to be reactive
  const activeRow = useRef<
    | {
        accessor: string;
        row_number: number;
      }
    | undefined
  >();
  const [activeOption, setActiveOption] = useState<TValue>();

  //TODO: selected document
  const [selectedDocument, setSelectedDocument] = useState<ITender>();

  useEffect(() => {
    if (tenders?.length) {
      setSelectedDocument(tenders[0]);
    }
  }, [tenders]);

  const selectedDocumentAccessor = useMemo(
    () =>
      selectedDocument && isTender(selectedDocument)
        ? `tenders.${selectedDocument?.id}`
        : "estimate",
    [selectedDocument]
  );

  const lotQuery = useQuery({
    queryKey: [QUERY_KEYS_ENUM.LOT, lotId],
    queryFn: () => fetchLot(lotId!),
    enabled: Boolean(lotId),
  });
  if (
    useHasChangedRef(lotQuery.data?.conventional_allotments) &&
    !allotment_type
  ) {
    const allotment_type = lotQuery.data?.conventional_allotments[0]!;
    setAllotment_type(allotment_type);
    setSearchParams(
      getSetSearchParamsCallbackWithLastUpdatedSearchParams((searchParams) => {
        searchParams.set(ALLOTMENT_TYPE_SEARCH_PARAM, allotment_type);
        return searchParams;
      }),
      { replace: true }
    );
  }

  const setInstructionType = useCallback(
    (subject_row: number, accessor: string, type: string | null) => {
      setInstructionsDirty((instructions) => {
        const dict: { [key: string]: IRowTypeInstruction } =
          accessNested(instructions, accessor) ?? {};
        dict[subject_row] = {
          ...dict[subject_row],
          subject_rows: [subject_row],
          type: ROW_TYPE_INSTRUCTIONS_ENUM.SET_TYPE,
          creation_date: new Date(),
          detail: {
            ...dict[subject_row]?.detail,
            type,
          },
        };
        setNested(instructions, accessor, dict);
        return { ...instructions };
      });
    },
    [setInstructionsDirty]
  );

  const currentInitialInstructions = initialInstructions.current;
  const [estimateInstructed, tendersInstructed] = useMemo(() => {
    // apply all instructions
    return [
      estimate
        ? applyRowTypeInstructions(estimate, [
            ...(currentInitialInstructions.estimate ?? []),
            ...Object.values(instructions.estimate ?? {}),
          ])
        : undefined,
      tenders.map((tender) =>
        applyRowTypeInstructions(tender, [
          ...(currentInitialInstructions.tenders[tender.id] ?? []),
          ...Object.values(instructions.tenders[tender.id] ?? {}),
        ])
      ),
    ];
  }, [estimate, tenders, instructions, currentInitialInstructions]);

  const priceEstimateRowTypeMappingMutation = useMutation({
    mutationFn: updateEstimateRowTypeMapping,
  });
  const tenderRowTypeMappingMutation = useMutation({
    mutationFn: updateTenderRowTypeMapping,
  });
  const tenderMappedPriceAveragesMutation = useMutation({
    mutationFn: updateTenderMappedPriceAverages,
  });

  // save all instructions
  const saveRowInstructions = useCallback(async () => {
    // TODO: only save on selected document
    const mapped_price_averages = extractMappedPriceAverages(tableRef.current);
    const mapped_rows = extractMappedRows(tableRef.current) ?? [];
    const tenderRowTypeQueries = tendersInstructed
      .filter((tender) => instructions.tenders[tender.id])
      .map((tender, index) =>
        tenderRowTypeMappingMutation.mutateAsync({
          id: tender.id,
          row_type_mapping: {
            row_type_instructions: [
              ...(currentInitialInstructions.tenders[tender.id] ?? []),
              ...Object.values(instructions.tenders[tender.id] ?? {}),
            ],
          },
        })
      );
    await Promise.all(tenderRowTypeQueries);

    const tenderMappedPriceAveragesQueries = tendersInstructed
      .filter((tender) => instructions.tenders[tender.id])
      .map((tender, index) => {
        const originalRows = extractChildrenRecursively(tender.data);
        return tenderMappedPriceAveragesMutation.mutateAsync({
          id: tender.id,
          mapped_price_averages,
          row_type_mapping_percentage: originalRows.length
            ? mapped_rows.length / originalRows.length
            : 0,
        });
      });
    await Promise.all(tenderMappedPriceAveragesQueries);

    // refresh data
    tendersInstructed
      .filter((tender) => instructions.tenders[tender.id])
      .forEach((tender) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS_ENUM.TENDER_ROW_TYPE_MAPPING, tender.id],
        });
      });
    setIsDirty(false);
  }, [
    tendersInstructed,
    instructions.tenders,
    tenderRowTypeMappingMutation,
    tenderMappedPriceAveragesMutation,
    queryClient,
    currentInitialInstructions,
  ]);

  const rowTypes = useRowTypesQuery(
    allotment_type,
    true,
    ROWTYPE_USAGE_ENUM.DPGF
  );

  const rowTypeCreation = useMutation({
    mutationFn: createRowType,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.ROW_TYPES, allotment_type],
      });
    },
  });

  const typeOptions = useMemo(() => {
    return rowTypes.data?.map(
      (rowType): IOption<IStdUnitMeta> => ({
        value: rowType.ref,
        label: `${rowType.ref} : ${rowType.description} ${
          rowType.unit && `(${rowType.unit})`
        }`,
        meta: {
          ...rowType,
        },
      })
    );
  }, [rowTypes.data]);

  const { organization } = useOrganizationNameRights(
    window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION!,
    { can_write: true }
  );
  const { organization: organizationAdmin } = useOrganizationNameRights(
    window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION!,
    { can_admin: true }
  );

  const additionalDocumentTopColumn = useMemo(
    () => ({
      header: t("mapping"),
      meta: {
        className: classNames(mappingToolPageStyles["type-column"]),
      },
      columns: [
        {
          header: t("row-type"),
          accessorKey: TABLE_COLUMN_DATA_TYPE_ENUM.ROW_TYPE,
          id: ROW_TYPE_COLUMN_ID,
          cell: (props: CellContext<ICompareRow, IAttribution>) => (
            <RowTypeCell
              props={props}
              typeOptions={typeOptions}
              rowTypes={rowTypes.data}
              setInstructionType={setInstructionType}
              setActiveRow={(row) => (activeRow.current = row)}
              setActiveOption={setActiveOption}
            />
          ),
          meta: {
            className: classNames(
              "text-center",
              styles["last-cell"],
              mappingToolPageStyles["type-column"]
            ),
            dataType: TABLE_COLUMN_DATA_TYPE_ENUM.ROW_TYPE,
          },
        },
      ],
    }),
    [t, typeOptions, rowTypes.data, setInstructionType]
  );

  const rows = useMemo(() => {
    return [
      compareColumnDisplaySettings?.includes(
        TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
      )
        ? estimateInstructed
        : undefined,
      ...tendersInstructed,
    ]
      .filter(Boolean)
      .flatMap(extractTypeRows);
  }, [compareColumnDisplaySettings, estimateInstructed, tendersInstructed]);

  const toolsHeader = useMemo(() => <MappingTableLegend />, []);

  const table = useCompareTable({
    baseEstimate: estimateInstructed,
    comparedDocuments: tendersInstructed,
    compareDifferenceSettings,
    compareColumnDisplaySettings,
    additionalDocumentTopColumn: additionalDocumentTopColumn as any,
    initialExpanded: true,
    toolsHeader,
  });

  const classificationMappingColumns = useMemo(
    () => [
      {
        header: t("standard-quantity"),
        accessorKey: "quantity",
        id: STANDARD_QUANTITY_COLUMN_ID,
        cell: (props: CellContext<any, any>) => {
          return (
            <StandardQuantityCellClassification
              props={props}
              setInstructions={setInstructionsDirty}
              baseAccessor={selectedDocumentAccessor}
              work_id={selectedDocument?.mapping?.works?.[0]?.work_id}
            />
          );
        },
        meta: {
          className: classNames(
            mappingToolPageStyles["medium-select-width"],
            mappingToolPageStyles["quantity-column"]
          ),
        },
      },
      {
        header: t("standard-price"),
        accessorKey: "price",
        id: STANDARD_PRICE_COLUMN_ID,
        cell: (props: CellContext<any, any>) =>
          formatCurrency(props.getValue()),
      },
      {
        header: t("unit_price"),
        accessorFn: (originalRow: IMappingPriceNode) =>
          originalRow.price! /
          (originalRow.quantity_std?.[0]?.quantity_std ??
            originalRow.quantity!),
        id: UNIT_PRICE_COLUMN_ID,
        cell: (props: CellContext<any, any>) =>
          formatCurrency(props.getValue()),
      },
    ],
    [setInstructionsDirty, t, selectedDocument, selectedDocumentAccessor]
  );

  const onRowClick = useCallback(
    (row: ReactRow<IRowTypeRow>) => {
      if (!isDocumentRow(row.original) && activeRow.current) {
        setInstructionType(
          activeRow.current.row_number,
          activeRow.current.accessor,
          row.original.ref
        );
        setActiveOption(row.original.ref);
      }
    },
    [setInstructionType]
  );

  const isSaving =
    priceEstimateRowTypeMappingMutation.isLoading ||
    tenderRowTypeMappingMutation.isLoading ||
    tenderMappedPriceAveragesMutation.isLoading ||
    isLoading;

  return (
    <>
      <Header variant="dark" nav={lotScopeNavList} />
      <Container
        fluid
        className="p-4 pt-5 pb-5 position-absolute h-100 top-0 d-flex flex-column"
      >
        <Row className="mb-4 mt-5 flex-shrink-0">
          <Col xs="auto" className="d-flex align-items-center">
            <Button
              variant="outline-secondary"
              className="rounded me-3"
              onClick={handleShowSettingsModal}
              disabled={
                isInitialLoading ||
                !compareDifferenceSettings ||
                !compareColumnDisplaySettings
              }
            >
              <CogIcon className="me-2" /> {t("settings")}
            </Button>
            <CompareSettingsModal
              show={showSettingsModal}
              handleClose={handleCloseSettingsModal}
              tenders={tenders}
              estimate={estimate}
              differenceSettings={compareDifferenceSettings}
              columnDisplaySettings={compareColumnDisplaySettings}
              onSubmit={({ display: { tenders, columns }, difference }) => {
                setCompareColumnDisplaySettings(columns);
                setCompareDifferenceSettings(difference);
                setTenders(tenders);
                handleCloseSettingsModal();
              }}
            />
          </Col>
          <Col>
            <AllotmentSelect
              multi={false}
              value={allotment_type}
              onChange={(value) => {
                setAllotment_type(value as string);
                setSearchParams(
                  getSetSearchParamsCallbackWithLastUpdatedSearchParams(
                    (searchParams) => {
                      searchParams.set(
                        ALLOTMENT_TYPE_SEARCH_PARAM,
                        value as string
                      );
                      return searchParams;
                    }
                  ),
                  { replace: true }
                );
              }}
              closeMenuOnSelect
            />
          </Col>
          <Col>
            <h5>{t("title")}</h5>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            {organizationAdmin && (
              <Nav>
                <Nav.Link
                  as={NavLink}
                  to={`/${PATH_NAMES_ENUM.MAPPING}/${PATH_NAMES_ENUM.ADMIN_ROW_TYPE}?${ALLOTMENT_TYPE_SEARCH_PARAM}=${allotment_type}`}
                >
                  {t("admin RowTypes")}
                </Nav.Link>
              </Nav>
            )}
            {(organization || organizationAdmin) && (
              <Button
                variant="outline-secondary"
                className="rounded me-3"
                onClick={handleShowRowTypeModal}
              >
                <AddCircledIcon fill={colors.black} className="me-2" />{" "}
                {t("add RowType")}
              </Button>
            )}
            <RowTypeModal
              key={String(showRowTypeModal)}
              show={showRowTypeModal}
              handleClose={async (rowType) => {
                if (rowType) {
                  await rowTypeCreation.mutateAsync(rowType);
                }
                handleCloseRowTypeModal();
              }}
              backErrors={rowTypeCreation.error as IBackErrors<IRowType>}
              isSubmitting={rowTypeCreation.isLoading}
              value={{ allotment_type: allotment_type! }}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <Button
              variant="outline-secondary"
              className="rounded me-3"
              onClick={saveRowInstructions}
              disabled={isSaving || !isDirty}
            >
              {isSaving ? (
                <Spinner size="sm" className="me-2" />
              ) : (
                <SaveIcon fill={colors.black} className="me-2" />
              )}
              {t("save")}
            </Button>
          </Col>
        </Row>
        <div className="d-flex">
          <div
            className={classNames(
              "overflow-auto",
              mappingToolPageStyles["standard-document-table"],
              "px-1",
              (isInitialLoading || isDataLoading) && "text-center"
            )}
            data-test={ifTest("mapping-table")}
          >
            {isInitialLoading || isDataLoading ? (
              <Spinner />
            ) : (
              <MappingTable
                table={table}
                isLoading={isDataLoading || isSaving}
              />
            )}
          </div>
          <div
            className={classNames(
              "overflow-auto",
              mappingToolPageStyles["classification-table"],
              "px-1"
            )}
            data-test={ifTest("classification-table")}
          >
            <ClassificationTable
              isLoading={rowTypes.isLoading}
              row_types={rowTypes.data}
              activeOption={activeOption as string | undefined}
              onRowClick={onRowClick}
              enableDownload
              enablePopupLink
              enableDisplayRows
              displayBase={
                [
                  compareColumnDisplaySettings?.includes(
                    TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
                  ),
                  ...tenders,
                ].filter(Boolean).length > 1
              }
              rows={rows}
              allotment_type={allotment_type}
              additionalColumns={classificationMappingColumns}
              initialInstructions={currentInitialInstructions}
              instructions={instructions}
              selectedDocumentAccessor={selectedDocumentAccessor}
              tableRef={tableRef}
            />
          </div>
        </div>
      </Container>
    </>
  );
}

function extractChildrenRecursively(priceNode?: IPriceNode): IPriceNode[] {
  return [
    ...(priceNode?.children ?? []),
    ...(priceNode?.children?.flatMap(extractChildrenRecursively) ?? []),
  ];
}

/** checks if the provided row is a Document row */
function isDocumentRow(row: IRowTypeRow) {
  return row.childs !== undefined;
}

const { t } = i18n;

function extractTypeRows(document?: ITender | TPriceEstimateWithTotal) {
  return extractTypeRowsRecursive(
    document && isTender(document) ? `tenders.${document?.id}` : "estimate",
    document?.data,
    (document as unknown as ITender)?.company_name ??
      t("useCompareTable.estimate"),
    document?.mapping?.works?.[0].work_id
  );
}

function extractTypeRowsRecursive(
  id: string,
  node?: IMappingPriceNode,
  base?: string,
  work_id?: string
): IMappingPriceNode[] {
  if (!node) {
    return [];
  }
  node.quantity = node.attributions?.reduce(
    (previous, attribution) => previous + (attribution.quantity ?? 0),
    0
  );
  node.price = node.unavailable
    ? undefined
    : node.attributions?.reduce(
        (previous, attribution) =>
          previous +
          (attribution.unit_price ?? 0) *
            (attribution.quantity ??
              node.quantity_std?.reduce(
                (previous, qty) => previous + qty.quantity_std,
                0
              ) ??
              0),
        0
      ) ?? node.total_price;
  const nodes = [];
  if (node.type) {
    // mark nodes as unavailable if it dosen't have a unit_price
    const unavailable = !node.attributions?.some(
      (attribution) => attribution.unit_price !== undefined
    );
    nodes.push({
      ...node,
      id,
      // copy children to be available in classificationTable as options
      childs: node.children,
      work_id,
      unavailable,
      base,
    });
  }
  nodes.push(
    ...(node.children?.flatMap((child) =>
      extractTypeRowsRecursive(id, child, base, work_id)
    ) ?? [])
  );
  return nodes;
}

function extractMappedPriceAverages(table?: Table<IRowTypeRow>) {
  return (
    table
      ?.getRowModel()
      .rows.filter(
        (row: any) =>
          row.original.childs === undefined &&
          row.original.price !== undefined &&
          (row.original.quantity_std?.[0]?.quantity_std ??
            row.original.quantity) !== undefined
      )
      .map((row: any) => ({
        ref: row.original.ref,
        price: row.original.price,
        quantity:
          row.original.quantity_std?.[0]?.quantity_std ?? row.original.quantity,
      })) ?? []
  );
}

/** extract all children of IPriceNode recursively into an array */
function extractMappedRows(table?: Table<IRowTypeRow>) {
  return table
    ?.getRowModel()
    .rows.filter((row: any) => row.original.row_number !== undefined);
}
